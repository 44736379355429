import React, { useEffect, useState } from "react";
import axios from "axios";
import { BorderTable } from "../BorderTable";
import { Dictionary, groupBy } from "lodash";
import { formatDate, SC_BASE_PATH, SC_BASE_URL } from "../../util/util";
import { Link } from "react-router-dom";
import { Grid, InlineCluster, Stack } from "@bedrock-layout/primitives";
import { CalendarProperties, CalendarActivity } from "../../datastructures";
import { CalendarMissingPropsErrorMessage } from "../CalendarMissingPropsErrorMessage";

export const Calendar: React.FC<CalendarProperties> = ({
  kiteGear,
  soarGear,
  spotData,
  homespotIds,
}) => {
  // prettier-ignore
  const [lastEnsRun, setLastEnsRun] = useState<Dictionary<CalendarActivity[]> | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    // const runsResponse = await axios.get(
    //   SC_TABLE_URL + "?table=ensemble_runs"
    // );
    //console.log(runsResponse.data);
    // //const lastEnsembleRun=runsResponse.data.sort((a,b) => b.run - a.run)[0];
    axios
      .get<CalendarActivity[]>(
        SC_BASE_PATH + "/scapi.php?table=mv_gefs_calendar&is_likely=true",
        {
          signal: controller.signal,
        }
      )
      .then((lastRunResponse) => {
        const filteredCalendar = lastRunResponse?.data
          ?.filter((a) => homespotIds.includes(a.spot_id))
          ?.filter(
            (a) =>
              (a.bigkite === kiteGear.bigKite &&
                a.medkite === kiteGear.medKite &&
                a.smallkite === kiteGear.smallKite) ||
              (a.bigwing === soarGear.bigWing &&
                a.smallwing === soarGear.smallWing)
          );

        const sortedCalendar = filteredCalendar.sort(
          (a, b) => a.validtime - b.validtime
        );
        const groupedCalendar = groupBy(
          sortedCalendar,
          (a: CalendarActivity) => a.validtime
        );

        // console.log(sortedCalendar);
        // console.log(groupedCalendar);
        // console.log(Object.entries(groupedCalendar).map((k,v) => ({validtime: k, events: v})));
        // console.log(Object.entries(groupedCalendar));
        setLastEnsRun(groupedCalendar);
      })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) {
          console.log("Error while fetching graph data ", thrown.message);
        }
      });
    return () => controller.abort();
  }, [kiteGear, soarGear, homespotIds]);

  if (!homespotIds?.length || !kiteGear || !soarGear) {
    return (
      <CalendarMissingPropsErrorMessage
        kiteGear={kiteGear}
        soarGear={soarGear}
        spotData={spotData}
        homespotIds={homespotIds}
      />
    );
  }

  const fromTime = (validtime: number) =>
    formatDate(validtime - 1.5 * 3600, "ccc d LLL T");
  const toTime = (validtime: number) => formatDate(validtime + 1.5 * 3600, "T");
  const readableBool = (bool: boolean) => (bool ? "Ja" : "Nee");
  const spotnames = homespotIds
    ? spotData?.filter((s) => homespotIds.includes(s.id))?.map((s) => s.name)
    : null;

  const randomPart = Math.random().toString(36).slice(-5);

  const icalKitegear = Object.keys(kiteGear).filter((key) =>
    Reflect.get(kiteGear, key)
  );
  const icalSoargear = Object.keys(soarGear).filter((key) =>
    Reflect.get(kiteGear, key)
  );

  const icalLinkGear = icalKitegear
    .concat(icalSoargear)
    .map((s: string) => s.toLowerCase())
    .map((s: string) => s + "=on")
    .reduce((prev: string, curr: string) => prev + "&" + curr);

  //TODO: soar gear
  const icalSpots = homespotIds
    .map((s) => "spot_" + s + "=on")
    .reduce((prev, cur) => prev + "&" + cur);
  const icalUrl =
    SC_BASE_URL +
    "/calendar.php?ical=true&r=" +
    randomPart +
    "&" +
    icalSpots +
    "&" +
    icalLinkGear;
  return (
    <>
      <h1>De komende tijd is er waarschijnlijk het volgende te doen:</h1>
      {lastEnsRun === null ? (
        "Loading"
      ) : Object.keys(lastEnsRun).length === 0 ? (
        <>
          Met het op het <Link to="/settings">Instellingen scherm</Link>{" "}
          geselecteerde materiaal is er op de geselecteerde spots voorlopig niks
          te beleven.
        </>
      ) : (
        <>
          <BorderTable>
            <tbody>
              {Object.entries(lastEnsRun).map(([validtime, events]) => (
                <tr key={validtime}>
                  <td>
                    {fromTime(parseInt(validtime))} tot{" "}
                    {toTime(parseInt(validtime))}
                  </td>
                  <td>
                    {events
                      .sort((a, b) => b.chance - a.chance)
                      .map((e) => (
                        <div key={e.activity_id}>
                          {e.activity_name} {e.chance} % kans
                        </div>
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </BorderTable>
        </>
      )}
      {kiteGear && soarGear ? (
        <>
          <h2>Dit is gebaseerd op de volgende materiaalkeuze:</h2>
          <InlineCluster gutter="xxl">
            <Stack gutter="xs">
              <h4>Kitesurfen</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Lichtweer kite:</td>
                    <td>{readableBool(kiteGear.bigKite)}</td>
                  </tr>
                  <tr>
                    <td>Midden kite:</td>
                    <td>{readableBool(kiteGear.medKite)}</td>
                  </tr>
                  <tr>
                    <td>Storm kite:</td>
                    <td>{readableBool(kiteGear.smallKite)}</td>
                  </tr>
                </tbody>
              </table>
            </Stack>

            <Stack gutter="xs">
              <h4>Soaren</h4>

              <table>
                <tbody>
                  <tr>
                    <td>Bergscherm:</td>
                    <td>{readableBool(soarGear.bigWing)}</td>
                  </tr>
                  <tr>
                    <td>Miniwing:</td>
                    <td>{readableBool(soarGear.smallWing)}</td>
                  </tr>
                </tbody>
              </table>
            </Stack>
          </InlineCluster>
          <br />
          {spotnames ? (
            <>
              <h2>En alleen voor de volgende spots</h2>
              <Grid gutter="md" minItemWidth="8rem">
                {spotnames.map((s) => (
                  <div key={s}>{s} </div>
                ))}
              </Grid>
            </>
          ) : (
            ""
          )}
          <br />
          Zie het <Link to="/settings">Instellingen scherm</Link> om dit aan te
          passen
          <div>
            <p>
              Om deze kansen in je telefoon of agenda te zetten abonneer je je
              agenda op de op de SoarCast lange termijn agenda voor deze
              selectie. Dat kun je doen door onderstaande URL te kopiëren en in
              je telefoon of google agenda als nieuwe agenda te abonneren.
              (iCalendar formaat)
            </p>
            <input type="text" value={icalUrl} size={100} readOnly={true} />
            <p>
              Let op dat de filters voor deze pagina ook werken voor de
              iCalendar. Stel deze dus goed in op de{" "}
              <Link to="/settings">settings pagina</Link> voor je het URL
              kopieert.
            </p>
            <p>
              Hoe je dit in je agenda krijgt, verschilt per agenda. Hieronder
              een lijstje van de meest voorkomende programma's / sites:
            </p>

            <ul>
              <li>
                Google calendar (Ook voor android)
                <br />
                <a
                  href="http://calendar.google.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  log in
                </a>{" "}
                -&gt; Klik op driehoekje rechts naast "Andere agenda's" -&gt;
                Toevoegen via URL -&gt; URL plakken -&gt; Agenda toevoegen{" "}
                <br />
                Let op: Dit instellen werkt niet op de mobiele site van google
                calendar. Als de kalender eenmaal toegevoegd is, is hij wel
                beschikbaar op een android telefoon. Als je dit instellen toch
                op een telefoon doet, klik dan na inloggen helemaal onderaan de
                pagina bij &quot;Weergeven&quot; op &quot;Google Desktop&quot;{" "}
              </li>
              <li>
                iPhone
                <br />
                Instellingen -&gt; Agenda -&gt; Acounts -&gt; Nieuw account
                -&gt; Anders -&gt; Voeg agenda abonnement toe -&gt; Plak bij
                Server de url die je hier boven gekopieerd hebt -&gt; volgende
                -&gt; Verander de beschrijving in iets als bijvoorbeeld:
                &quot;SoarCast&quot; -&gt; Bewaar{" "}
              </li>
              <li>
                Outlook 2013
                <br />
                bestand -&gt; accountinstellingen -&gt; internetagenda's -&gt;
                nieuw -&gt; url toevoegen -&gt; de persoonlijke instellingen
                instellen -&gt; ok
              </li>
              <li>
                Thunderbird (met Lightning plugin)
                <br />
                Klik op &quot;Calendar&quot; tabblad. -&gt; right click in de
                linker kantlijn onder &quot;Calendar&quot; -&gt; New Calendar
                -&gt; select &quot;On the network&quot; selecteer
                &quot;iCalendar (ICS)&quot; en plak bij &quot;Location&quot; het
                URL wat je hierboven gekopieerd hebt. -&gt; next -&gt; Vul een
                naam in (bijv. SoarCast ) en kies evt een kleur die afwijkt van
                de kalenders die je al hebt. -&gt; Next
              </li>
              <li>
                Outlook.com (Ook windows Phone)
                <br />
                Ga in je browser naar je outlook.com account -&gt; outlook
                agenda -&gt; agenda toevoegen -&gt; van internet -&gt; vul de
                gegevens in -&gt; opslaan.
                <br /> Hierna zullen alle apparaten met die syncen met je
                outlook.com account de extra agenda bevatten. Afhankelijk van je
                instelling kan het wel een paar minuten duren.
              </li>
              <li>
                Overig
                <br />
                Hoe dit in te stellen verschilt per programma, maar een paar
                tips:
              </li>
              <li
                style={{
                  listStyle: "none",
                  backgroundImage: "none",
                  backgroundPosition: 0,
                }}
              >
                <ul>
                  <li>
                    {" "}
                    Zorg dat je &quot;abonneert&quot; en niet
                    &quot;importeert&quot; Bij importeren wordt eenmalig gekeken
                    wat er op SoarCast staat. Bij abonneren wordt er regelmatig
                    gerefreshed, en krijg je er dus nieuwe afspraken bij als de
                    voorspelling verandert.
                  </li>
                  <li>
                    Het url waarop je je abonneert is een &quot;ical&quot; of
                    &quot;ics&quot; link. Als je die opties niet hebt, kun je
                    nog &quot;caldav&quot; proberen. Dat werkt soms ook.
                  </li>
                </ul>
              </li>
            </ul>

            <h4>Bekijk ook meteen even:</h4>
            <ul>
              <li>
                {" "}
                <a
                  href="http://www.knmi.nl/waarschuwingen_en_verwachtingen/weerkaarten.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  KNMI Weerkaartjes
                </a>
              </li>

              <li>
                {" "}
                <a
                  href="http://www.knmi.nl/nederland-nu/weer/waarschuwingen-en-verwachtingen/extra/guidance-modelbeoordeling"
                  target="_blank"
                  rel="noreferrer"
                >
                  Modelbeoordeling
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="http://www.buienradar.nl/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Buienradar
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="http://www.knmi.nl/waarschuwingen_en_verwachtingen/luchtvaart/weerbulletin_kleine_luchtvaart.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Weerbulletin voor de kleine luchtvaart
                </a>{" "}
                <a
                  href="http://nos.nl/teletekst#707"
                  target="_blank"
                  rel="noreferrer"
                >
                  (via teletekst)
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="http://blipmaps.nl/index.php?Day=0&Lang=Nl&Stage=2"
                  rel="noreferrer"
                  target="_blank"
                >
                  RASP voorspelling voor Nederland
                </a>{" "}
              </li>
            </ul>
            <h4>Bron:</h4>

            <p>
              <b>Voorspellingen op deze pagina(kalender)</b>: Global Ensemble
              Forecast van{" "}
              <a href="http://www.noaa.gov/" target="_blank" rel="noreferrer">
                NOAA
              </a>{" "}
              Ieder van de 20 GEFS ensemble leden die voor de gegeven tijd
              matcht met de limieten voor een activiteit geeft 5% meer kans op
              die activiteit op het gegeven tijdstip.
              <br />
              Voorspellingen worden alleen verzameld voor locaties waar ook een
              meting van beschikbaar is. Voor de limieten en gebruikte
              weerstations van iedere activiteit, zie op de pagina van de
              specifieke activiteit.
            </p>
          </div>
        </>
      ) : (
        "Loading"
      )}
    </>
  );
};
