import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  Chart,
  ChartData,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { Scatter } from "react-chartjs-2";
import {
  clone,
  friendlyWindUnitName,
  graphHeight,
  SC_BASE_PATH,
} from "../util/util";
import { WindowSize } from "../datastructures";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

ChartJS.defaults.font.size = 12;

const labelCallback = (units: string) =>
  function (context: any): string {
    if (context == null) {
      return "";
    }
    let label = context.dataset.label || "";

    if (label) {
      label = context.label + " " + label + ": ";
    }
    if (context.parsed.y !== null) {
      label += context.parsed.y + " " + units;
    }
    return label;
  };

const strip_empty_label_plugin = function (
  this: {
    generateLabels: (chart: Chart<"scatter">) => {
      text: string | undefined;
      fillStyle:
        | string
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => import("chart.js").Color | undefined)
        | import("chart.js/types/utils")._DeepPartialObject<CanvasGradient>
        | import("chart.js/types/utils")._DeepPartialObject<CanvasPattern>
        | readonly (
            | string
            | import("chart.js/types/utils")._DeepPartialObject<CanvasGradient>
            | import("chart.js/types/utils")._DeepPartialObject<CanvasPattern>
            | undefined
          )[]
        | undefined;
      hidden: boolean;
      lineCap:
        | "butt"
        | "round"
        | "square"
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => CanvasLineCap | undefined)
        | undefined;
      lineDash:
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => number[] | undefined)
        | import("chart.js/types/utils")._DeepPartialArray<number>
        | undefined;
      lineDashOffset:
        | number
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => number | undefined)
        | undefined;
      lineJoin:
        | "round"
        | "bevel"
        | "miter"
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => CanvasLineJoin | undefined)
        | undefined;
      lineWidth:
        | number
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => number | undefined)
        | readonly (number | undefined)[]
        | undefined;
      strokeStyle:
        | string
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => import("chart.js").Color | undefined)
        | import("chart.js/types/utils")._DeepPartialObject<CanvasGradient>
        | import("chart.js/types/utils")._DeepPartialObject<CanvasPattern>
        | readonly (
            | string
            | import("chart.js/types/utils")._DeepPartialObject<CanvasGradient>
            | import("chart.js/types/utils")._DeepPartialObject<CanvasPattern>
            | undefined
          )[]
        | undefined;
      pointStyle:
        | "line"
        | "circle"
        | "cross"
        | "crossRot"
        | "dash"
        | "rect"
        | "rectRounded"
        | "rectRot"
        | "star"
        | "triangle"
        | ((
            ctx: import("chart.js").ScriptableContext<"line">,
            options: import("chart.js/types/basic").AnyObject
          ) => import("chart.js").PointStyle | undefined)
        | import("chart.js/types/utils")._DeepPartialObject<HTMLImageElement>
        | import("chart.js/types/utils")._DeepPartialObject<HTMLCanvasElement>
        | readonly (
            | "line"
            | "circle"
            | "cross"
            | "crossRot"
            | "dash"
            | "rect"
            | "rectRounded"
            | "rectRot"
            | "star"
            | "triangle"
            | import("chart.js/types/utils")._DeepPartialObject<HTMLImageElement>
            | import("chart.js/types/utils")._DeepPartialObject<HTMLCanvasElement>
            | undefined
          )[]
        | undefined;
      // Below is extra data used for toggling the datasets
      datasetIndex: number;
    }[];
  },
  chart: Chart<"scatter">
) {
  const data = chart.data;
  const helper = {
    isArray: Array.isArray
      ? Array.isArray
      : function (value: any) {
          return Object.prototype.toString.call(value) === "[object Array]";
        },
  };

  const res = helper.isArray(data.datasets)
    ? data.datasets.map(function (dataset, i) {
        return {
          text: dataset.label,
          fillStyle: dataset.backgroundColor,
          hidden: !chart.isDatasetVisible(i),
          lineCap: dataset.borderCapStyle,
          lineDash: dataset.borderDash,
          lineDashOffset: dataset.borderDashOffset,
          lineJoin: dataset.borderJoinStyle,
          lineWidth: dataset.borderWidth,
          strokeStyle: dataset.borderColor,
          pointStyle: dataset.pointStyle,

          // Below is extra data used for toggling the datasets
          datasetIndex: i,
        };
      }, this)
    : [];
  return res.filter(function (opt) {
    return opt.text !== "";
  }); // Only show labels that have a non-empty text (e.g. filter out some windrange labels)
};

export const optionsWindDir = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      title: {
        display: true,
        text: "Tijd",
      },
      distribution: "linear",
      bounds: "data",
      time: {
        // min: 1652997600000,
        // max: 1653084000000,
        displayFormats: {
          hour: "H:mm",
          minute: "H:mm",
        },
        tooltipFormat: "H:mm",
      },
      ticks: {
        beginAtZero: false,
        maxRotation: 0,
      },
    },
    y: {
      suggestedMin: 0,
      suggestedMax: 360,

      distribution: "linear",
      title: {
        display: true,
        text: "Windrichting (graden)",
      },
      ticks: {
        stepSize: 45,
      },
    },
  },

  plugins: {
    title: {
      text: "TODO Make this title dynamic",
      display: true,
    },
    legend: {
      display: true,
      labels: {
        generateLabels: strip_empty_label_plugin,
      },
    },
    tooltip: {
      enabled: true,
      axis: "xy",
      mode: "x",
      intersect: false,
      callbacks: {
        //label: labelCallback("graden"),
      },
    },
  },
};

export const optionsWindSpd = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      distribution: "linear",
      title: {
        display: true,
        text: "Windsnelheid (knopen)",
      },
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 30,
      },
    },
    x: {
      type: "time",
      title: {
        display: true,
        text: "Tijd",
      },

      distribution: "linear",
      bounds: "data",
      time: {
        displayFormats: {
          hour: "H:mm",
          minute: "H:mm",
        },
        tooltipFormat: "H:mm",
      },
      ticks: {
        beginAtZero: true,
        maxRotation: 0,
      },
    },
  },

  plugins: {
    title: {
      text: "Wind IJmuiden Zuid havenhoofd 2022-May-22",
      display: true,
    },

    legend: {
      display: true,
      labels: {
        generateLabels: strip_empty_label_plugin,
      },
    },
    tooltip: {
      enabled: true,
      axis: "xy",
      mode: "x",
      intersect: false,
      callbacks: {
        //label: labelCallback("blaa"),
      },
    },
  },
};

//TODO eenheden omrekenen

// switch ($unifiedUnit){
//   case 'wind':
//     //$options['scales']['yAxes'][0]['ticks']['min'] =0;
//     //$options['scales']['yAxes'][0]['ticks']['max'] =30;
//     $options['scales']['yAxes'][1]=array();
//     $options['scales']['yAxes'][0]['id']='spd';
//     $options['scales']['yAxes'][1]['id']='dir';
//     $options['scales']['yAxes'][1]['ticks']['min'] =0;
//     $options['scales']['yAxes'][1]['ticks']['max'] =360;
//     $options['scales']['yAxes'][1]['ticks']['stepSize']=45;
//     $options['scales']['yAxes'][1]['position']='right';
//     $options['scales']['yAxes'][1]['scaleLabel']=array();
//     $options['scales']['yAxes'][1]['scaleLabel']['labelString']='Graden';
//     $options['scales']['yAxes'][1]['scaleLabel']['display']=true;

//     $u=friendlyWindUnitName($windUnits);
//     $graphTitle='Wind';
//     break;

// case 'm/s':
//     //$options['scales']['yAxes'][0]['ticks']['min'] =0;
//     //$options['scales']['yAxes'][0]['ticks']['max'] =30;
//     $u=friendlyWindUnitName($windUnits);
//     $graphTitle='Wind';
//     break;
//   case 'deg':
//     $options['scales']['yAxes'][0]['ticks']['min'] =0;
//     $options['scales']['yAxes'][0]['ticks']['max'] =360;
//     $graphTitle='Windrichting';
//     break;
//   case 'C':
//     $options['scales']['yAxes'][0]['ticks']['beginAtZero']=false;
//     $u='Graden Celcius';
//     $graphTitle='Temperatuur';
//     break;
//   case 'hPa':
//     $lowVal=null;
//     $highVal=null;
//     foreach ($data as $r){
//       foreach ($r as $f){
//         if ($f<10000){ // De etime moet niet mee gerekend worden
//           $lowVal=minnotnull($lowVal, $f);
//           $highVal=maxnotnull($highVal, $f);
//         }
//       }
//     }
//     $midVal=ceil(($lowVal+$highVal)/2);
//     #echo "L $lowVal h $highVal m $midVal";
//     $u='Hectopascal';
//     $graphTitle='Luchtdruk';
//     $options['scales']['yAxes'][0]['ticks']['min'] =$midVal - 12;
//     $options['scales']['yAxes'][0]['ticks']['max'] =$midVal + 12;

//     break;

// function friendlyWindUnitName($windUnits){
//   switch ($windUnits){
//   case 'kts':
//     $u='Knopen';
//     break;
//   case 'kmh';
//     $u='Kilometer per uur';
//     break;
//   default:
//     $u='Meters per seconde';
//   }
//   return $u;
// }

// Options changes from old soarcast format:
// Move title and legend into plugins
// removed surrounding options

// Data changes from old soarcast format:
// No new date(...)
// multiply timestamps by 1000
// Removed         "yAxes": [  (and also for y)
// Changed "t" to"x"
// Add showLine: true,

// location and day specified in query
// unifiedUnit in params

interface SoarcastGraphProperties {
  locationId: number;
  locationName: string;
  activityId?: number;
  unifiedUnit: string;
  windUnits?: string;
  day: string;
  duringDayOnly: boolean;
  windowSize: WindowSize;
  runsToShow: string;
}

interface GraphData {
  data: ChartData<"scatter", unknown[]>;
}

export const SoarcastGraph: React.FC<SoarcastGraphProperties> = ({
  locationId,
  locationName,
  activityId,
  unifiedUnit,
  windUnits,
  day,
  duringDayOnly,
  windowSize,
  runsToShow,
}) => {
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const activityUrlString = activityId ? "&activity_id=" + activityId : "";
  const duringDayOnlyString = duringDayOnly
    ? "&duringDayOnly=" + duringDayOnly
    : "";

  //console.log(windowSize);
  //console.log(graphHeight(windowSize));
  useEffect(() => {
    const runsString = runsToShow ? "&runsToShow=" + runsToShow : "";
    const controller = new AbortController();

    if (unifiedUnit && locationId && day && runsToShow) {
      const url =
        SC_BASE_PATH +
        "/plotPerLocAndUnitReact.php?json&jsonOnly&unit=" +
        unifiedUnit +
        "&location=" +
        locationId +
        "&day=" +
        day +
        "&windUnits=" +
        windUnits +
        "&forecastToShow=harm" +
        activityUrlString +
        duringDayOnlyString +
        runsString;

      axios
        .get(url, { signal: controller.signal })
        .then((response) => setGraphData(response.data))
        .catch((thrown) => {
          if (!axios.isCancel(thrown)) {
            console.log("Error while fetching graph data ", thrown.message);
          }
        });
      return () => controller.abort();
    }
  }, [
    locationId,
    locationName,
    unifiedUnit,
    windUnits,
    day,
    activityUrlString,
    duringDayOnlyString,
    runsToShow,
  ]);

  let options;

  switch (unifiedUnit) {
    case "deg":
      options = clone(optionsWindDir);
      options.plugins.legend.labels.generateLabels = strip_empty_label_plugin;
      options.scales.y.title.text = "Windrichting (graden)";
      options.plugins.title.text =
        "Windrichting (graden) " + locationName + " " + day;
      options.scales.y.suggestedMin = 0;
      options.scales.y.suggestedMax = 360;
      options.scales.y.ticks.stepSize = 45;
      options.plugins.tooltip.callbacks.label = labelCallback("graden");

      break;
    case "m/s":
      options = clone(optionsWindSpd);
      const friendlyUnit = windUnits ? friendlyWindUnitName(windUnits) : "";
      options.plugins.legend.labels.generateLabels = strip_empty_label_plugin;
      options.scales.y.suggestedMin = 0;
      options.scales.y.title.text = "Windsnelheid (" + friendlyUnit + ")";
      options.plugins.title.text =
        "Windsnelheid (" + friendlyUnit + ") " + locationName + " " + day;
      options.plugins.tooltip.callbacks.label = labelCallback(friendlyUnit);

      break;
    case "C":
      options = clone(optionsWindDir); //TODO heeft deze een eigen nodig?
      options.plugins.legend.labels.generateLabels = strip_empty_label_plugin;
      options.scales.y.title.text = "Temperatuur (Graden C.)";
      options.scales.y.suggestedMin = 0;
      options.scales.y.suggestedMax = 30;
      options.scales.y.ticks.stepSize = 10;
      options.scales.y.ticks = {
        stepSize: 5,
      };
      options.plugins.title.text =
        "Temperatuur (Graden C.) " + locationName + " " + day;
      options.plugins.tooltip.callbacks.label = labelCallback(" C.");

      break;
    case "hPa":
      options = clone(optionsWindDir); //TODO heeft deze een eigen nodig?
      options.plugins.legend.labels.generateLabels = strip_empty_label_plugin;
      options.plugins.title.text =
        "Luchtdruk (hectopascal) " + locationName + " " + day;
      options.scales.y.title.text = "Luchtdruk (hectopascal)";
      //TODO: ticks.minval en maxval mediaan + en min 12
      options.scales.y.suggestedMin = 980;
      options.scales.y.suggestedMax = 1020;
      options.scales.y.ticks.stepSize = 5;
      options.plugins.tooltip.callbacks.label = labelCallback(" hPa");

      break;
    default:
      // By setting options to null, at least it will become apparent that we forgot a unified unit...
      options = null;
      break;
  }

  return (
    <>
      <br />
      {/* Portrait: {portrait ? "yep" : "Nope"}
      <br />
      graphHeight: {graphHeight}
      <br />
      windowSize:{windowSize.innerWidth + "x" + windowSize.innerHeight}
     */}
      <div style={{ height: graphHeight(windowSize), position: "relative" }}>
        {graphData != null && graphData.data != null ? (
          <Scatter options={options} data={graphData.data} />
        ) : (
          "Loading graph data"
        )}
      </div>
    </>
  );
};
