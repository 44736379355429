import { useQueryParam } from "./useQueryParam";
import React, { useEffect } from "react";
import { QueryParamConfig } from "serialize-query-params/src/types";

export function useQueryStateParam<T>(
  name: string,
  paramType: QueryParamConfig<T | null, T | null | undefined>,
  stateValue: T | null | undefined,
  setStateValue: React.Dispatch<React.SetStateAction<T>> | undefined,
): [T, (value: T) => void] {
  const [queryValue, setQueryValue] = useQueryParam(name);
  const encodedStateValue = stateValue ? paramType.encode(stateValue) : null;

  useEffect(() => {
    // comparing the json encoded strings here for a reliable equality check
    if (encodedStateValue !== queryValue) {
      if (queryValue) {
        if (setStateValue) {
          setStateValue(paramType.decode(queryValue) as T);
        }
      } else {
        setQueryValue(encodedStateValue as string);
      }
    }
  }, [
    paramType,
    stateValue,
    encodedStateValue,
    queryValue,
    setQueryValue,
    setStateValue,
  ]);

  return [
    stateValue as T,
    (value) => setQueryValue(paramType.encode(value) as string),
  ];
}
