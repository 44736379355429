import React from "react";

export const OverviewLegend: React.FC = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/t_0.svg"}
              width="30"
              height="30"
              alt="nowind icon"
            ></img>
            Geen opties
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/t_1.svg"}
              width="30"
              height="30"
              alt="big kite icon"
            ></img>
            Grote kite
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/t_2.svg"}
              width="30"
              height="30"
              alt="middle kite icon"
            ></img>
            Midden kite
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/t_4.svg"}
              width="30"
              height="30"
              alt="small kite icon"
            ></img>
            Stormkite
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/t_7.svg"}
              width="30"
              height="30"
              alt="all kites icon"
            ></img>
            Ergens tussen kite-maten in
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/t_16.svg"}
              width="20"
              height="20"
              alt="big wing icon"
            ></img>
            Bergscherm
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/t_32.svg"}
              width="20"
              height="20"
              alt="miniwing icon"
            ></img>
            Miniwing
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};
