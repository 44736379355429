import React from "react";
import { Link } from "react-router-dom";
import { CalendarProperties } from "../datastructures";

export const CalendarMissingPropsErrorMessage: React.FC<CalendarProperties> = ({
  kiteGear,
  soarGear,
  spotData,
  homespotIds,
}) => {
  var missing = [];
  if (!homespotIds?.length) {
    missing.push("homespots");
  }
  if (!kiteGear || !soarGear) {
    missing.push("materiaal");
  }

  console.log(missing);
  return (
    <>
      <br />
      <br />
      <center>
        Voordat je de kalender kunt gebruiken moet je eerst{" "}
        {missing.join(" en ")} kiezen op het{" "}
        <Link to="/settings">instellingen scherm.</Link>
      </center>
    </>
  );
};
