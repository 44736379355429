import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  ChartData,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { Scatter } from "react-chartjs-2";
import { graphHeight, SC_BASE_PATH } from "../util/util";
import { WindowSize } from "../datastructures";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

ChartJS.defaults.font.size = 12;

// Options changes from old soarcast format:
// Move title and legend into plugins
// removed surrounding options

// Data changes from old soarcast format:
// No new date(...)
// multiply timestamps by 1000
// Removed         "yAxes": [  (and also for y)
// Changed "t" to"x"
// Add showLine: true,

// location and day specified in query
// unifiedUnit in params

interface ActivityChanceGraphProperties {
  activityId: number;
  activityName: string;
  windowSize: WindowSize;
}

interface GraphData {
  data: ChartData<"scatter", number[]>;
}

export const ActivityChanceGraph: React.FC<ActivityChanceGraphProperties> = ({
  activityId,
  activityName,
  windowSize,
}) => {
  const [graphData, setGraphData] = useState<GraphData | null>(null);

  useEffect(() => {
    const controller = new AbortController();

    const url =
      SC_BASE_PATH +
      "/plotActivityChanceGraphReact.php?json&activity=" +
      activityId; //&run=2022052406"

    axios
      .get<GraphData>(url, { signal: controller.signal })
      .then((response) => setGraphData(response.data))
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) {
          console.log("Error while fetching graph data ", thrown.message);
        }
      });
    return () => controller.abort();
  }, [activityId]);

  const options: any =
    graphData === null
      ? {}
      : {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              suggestedMin: 0,
              suggestedMax: 100,
              distribution: "linear",
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 100,
              },
              title: {
                display: true,
                text: "% van modelruns met weer voor " + activityName,
              },
            },
            x: {
              type: "time",
              distribution: "linear",
              bounds: "data",
              adapters: {
                date: {
                  locale: "nl-NL",
                },
              },
              time: {
                displayFormats: {
                  hour: "ccc d LLL H:mm",
                },
                tooltipFormat: "ccc d LLL H:mm",
              },
              ticks: {
                beginAtZero: false,
                maxRotation: 0,
              },
            },
          },
          plugins: {
            title: {
              text: "Kans op " + activityName,
              display: true,
            },
            legend: {
              display: false,
            },

            tooltip: {
              callbacks: {
                label: function (context: any) {
                  return context.label + ": " + context.parsed.y + " % kans";
                },
              },
            },
          },
        };

  //console.log(options);
  //console.log(graphData);

  return (
    <>
      <br />

      <div
        style={{
          height: graphHeight(windowSize),
          position: "relative",
        }}
      >
        {graphData != null && graphData.data != null ? (
          <Scatter options={options} data={graphData.data} />
        ) : (
          "Loading chance graph data"
        )}
      </div>
    </>
  );
};
