import { NumberParam, StringParam } from "use-query-params";
import { SoarcastGraph } from "../SoarcastGraph";
import { DuringDayOnlySwitch } from "../DuringDayOnlySwitch";
import { ActivityChanceGraph } from "../ActivityChanceGraph";
import { ActivityLimitsTable } from "../ActivityLimitsTable";
import { SanitizedDynamicHtml } from "../SanitizedDynamicHtml";
import { SportSetting } from "../SportSetting";
import { useQueryCookieParam } from "../../util/useQueryCookieParam";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useQueryStateParam } from "../../util/useQueryStateParam";
import { Center } from "@bedrock-layout/center";
import { TideGraphs } from "../TideGraphs";
import { getSportFromHostname, SC_BASE_PATH } from "../../util/util";

import {
  ActivityData,
  ActivityLimits,
  ExtraGraphsData,
  GraphsData,
  KiteGear,
  LocationData,
  SoarGear,
  SpotData,
  TideWindData,
  WindowSize,
} from "../../datastructures";
import { AppContext } from "../../util/Contexts";
import { ActueleWindMeting } from "../ActueleWindMeting";
import { NkvSpotLinks } from "../NkvSpotLinks";
import { useStaticTableDataFromRemote } from "../../util/useStaticTableDataFromRemote";
import { ActueelGetij } from "../ActueelGetij";
import ConditionalRender from "../ConditionalRender";
import { MeasurementSourceLink } from "../MeasurementSourceLink";

interface SpotInfoProperties {
  spotData: SpotData[];
  locationData: LocationData[];
  windUnits: string;
  kiteGear: KiteGear;
  soarGear: SoarGear;
  activitiesData: ActivityData[];
  graphsData: GraphsData[];
  extraGraphsData: ExtraGraphsData[];
  actLimitData: ActivityLimits[];
  duringDayOnly: boolean;
  setDuringDayOnly: Dispatch<SetStateAction<boolean>>;
  windowSize: WindowSize;
}

export const SpotInfo: React.FC<SpotInfoProperties> = ({
  spotData,
  locationData,
  windUnits,
  kiteGear,
  soarGear,
  activitiesData,
  graphsData,
  extraGraphsData,
  actLimitData,
  duringDayOnly,
  setDuringDayOnly,
  windowSize,
}) => {
  const appContextData = useContext(AppContext);

  const [day, setDay] = useQueryStateParam<string>(
    "day",
    StringParam,
    appContextData?.dayState,
    appContextData?.setDayState
  );
  const [runsToShow, setRunsToShow] = useState<string>("lastOnly");

  const sportFromHostname = getSportFromHostname();

  const [sport, setSport] = useQueryCookieParam(
    "sport",
    StringParam,
    sportFromHostname
  );

  const [spot, setSpot] = useQueryCookieParam<number>("spot", NumberParam, 1);

  const spotDataForSport = spotData.filter(
    (s) => (sport === "kite" && s.kite) || (sport === "soar" && s.soar)
  );

  const selectedKiteActivity = activitiesData.find(
    (a) =>
      a.kiten &&
      a.spot_id === spot &&
      a.bigkite === kiteGear.bigKite &&
      a.medkite === kiteGear.medKite &&
      a.smallkite === kiteGear.smallKite
  );

  const selectedSoarActivity = activitiesData.find(
    (a) =>
      a.soaren &&
      a.spot_id === spot &&
      a.bigwing === soarGear.bigWing &&
      a.smallwing === soarGear.smallWing
  );

  const onSpotChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setSpot(parseInt(e.target.value));
  };

  const onRunsChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setRunsToShow(e.target.value);
  };

  const selectedAct =
    sport === "soar" ? selectedSoarActivity : selectedKiteActivity;

  const activity = selectedAct?.id;

  const selectedActName = selectedAct?.name || "Onbekende activiteit";

  const selectedActSpotId = selectedAct?.spot_id;

  const selectedGraphs = graphsData
    ?.filter((a) => a.activity_id === activity)
    .sort((a, b) => b.unifiedunits.localeCompare(a.unifiedunits));

  const selectedExtraGraphs = extraGraphsData?.filter(
    (a) => a.act_id === activity
  );

  const selectedSpotData = spotData.find((s) => s.id === selectedActSpotId);

  const fakeSpotForSpotNa = { id: 0, name: "Selecteer spot" };
  const spotDropdownData = spotDataForSport
    ? selectedSpotData
      ? spotDataForSport
      : [fakeSpotForSpotNa, ...spotDataForSport]
    : [];

  const selectedSpotRemarks = selectedSpotData?.remarks;
  const selectedActRemarks = selectedAct?.remarks;

  const tideWindData = useStaticTableDataFromRemote<TideWindData>(
    "table=mv_tide_wind_data&spot_id=" + spot
  );

  return (
    <div>
      <SportSetting sport={sport} setSport={setSport} />
      {spotDropdownData ? (
        <>
          <select onChange={onSpotChange} value={spot}>
            {spotDropdownData.map((spot) => (
              <option key={spot.id} value={spot.id}>
                {spot.name}
              </option>
            ))}
          </select>{" "}
          <select onChange={onRunsChange} value={runsToShow}>
            <option key={"lastOnly"} value="lastOnly">
              Alleen de meest recente model run voor ieder tijdstip
            </option>
            <option key={"raw"} value="raw">
              Alle model runs
            </option>
            <option key={"all"} value="all">
              Alle model runs en de meest recente
            </option>
          </select>
          <br />
        </>
      ) : (
        "loading"
      )}
      <button onClick={() => setDay("yesterday")}>Gisteren</button>
      <button onClick={() => setDay("today")}>Vandaag</button>
      <button onClick={() => setDay("tomorrow")}>Morgen</button>
      <button onClick={() => setDay("dayaftertomorrow")}>
        Overmorgen
      </button>{" "}
      <DuringDayOnlySwitch
        duringDayOnly={duringDayOnly}
        setDuringDayOnly={setDuringDayOnly}
      />
      <h1>
        {selectedActName} {day}
      </h1>
      <ul>
        <li>
          <ActueleWindMeting spotId={spot} />
        </li>
        <ConditionalRender condition={sport === "kite"}>
          <ActueelGetij spotId={spot} tideWindData={tideWindData} />
        </ConditionalRender>
      </ul>
      <ConditionalRender condition={sport === "kite"}>
        <NkvSpotLinks spotId={spot} />{" "}
      </ConditionalRender>
      <br />
      {!selectedSpotData ? (
        <>
          <br />
          <br />
          <br />
          <br />
          <Center>Kies eerst een spot</Center>
        </>
      ) : (
        <div>
          {(selectedSpotRemarks ?? selectedActRemarks) == null ? "" : <br />}
          {selectedActRemarks && (
            <SanitizedDynamicHtml dynamicUnsafeHtml={selectedActRemarks} />
          )}
          {selectedSpotRemarks && (
            <SanitizedDynamicHtml dynamicUnsafeHtml={selectedSpotRemarks} />
          )}
          {selectedGraphs ? (
            <>
              {selectedGraphs.map((graph) => (
                <SoarcastGraph
                  locationId={graph.location_id}
                  locationName={graph.location_name}
                  day={day}
                  unifiedUnit={graph.unifiedunits}
                  windUnits={windUnits}
                  activityId={activity}
                  key={graph.unifiedunits}
                  duringDayOnly={duringDayOnly}
                  windowSize={windowSize}
                  runsToShow={runsToShow}
                />
              ))}
            </>
          ) : (
            "loading"
          )}

          <TideGraphs
            spot={spot}
            tideWindData={tideWindData}
            day={day}
            windowSize={windowSize}
            selectedSpotData={selectedSpotData}
            duringDayOnly={duringDayOnly}
            windUnits={windUnits}
          />

          {selectedExtraGraphs ? (
            <>
              {selectedExtraGraphs.map((graph) => (
                <SoarcastGraph
                  locationId={graph.location_id}
                  locationName={graph.location_name}
                  day={day}
                  unifiedUnit={graph.unit}
                  key={graph.unit}
                  duringDayOnly={duringDayOnly}
                  windowSize={windowSize}
                  runsToShow={runsToShow}
                />
              ))}
            </>
          ) : (
            "loading"
          )}
          {activity ? (
            <ActivityChanceGraph
              key="chancegraph"
              activityId={activity}
              activityName={selectedActName}
              windowSize={windowSize}
              //TODO add run
            />
          ) : (
            ""
          )}
          <br />
          {activity ? (
            <ActivityLimitsTable
              activityId={activity}
              windUnits={windUnits}
              actLimitData={actLimitData}
            />
          ) : (
            ""
          )}
          <h4>Bron:</h4>
          <p>
            {selectedSpotData && locationData ? (
              <>
                <b>Windmetingen</b>:{" "}
                <MeasurementSourceLink
                  location={selectedSpotData.wind_location}
                  allLocationData={locationData}
                  linkLocationLocal={true}
                />
                <br />
                {selectedSpotData.temp_location ? (
                  <>
                    <b>Temperatuurmetingen</b>:{" "}
                    <MeasurementSourceLink
                      location={selectedSpotData.temp_location}
                      allLocationData={locationData}
                      linkLocationLocal={true}
                    />
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {selectedSpotData.presure_location ? (
                  <>
                    <b>Luchtdrukmetingen</b>:{" "}
                    <MeasurementSourceLink
                      location={selectedSpotData.presure_location}
                      allLocationData={locationData}
                      linkLocationLocal={true}
                    />
                    <br />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            Voor de GPS locaties van alle weerstations zie:{" "}
            <a href={SC_BASE_PATH + "/weerstations.kml.php"}>
              google earth overzicht van de gebruikte weerstations
            </a>{" "}
            of de &quot;(maps)&quot; link van een specifiek weerstation.
            <br />
            <b>Weer voorspellingen in de meting grafieken op deze pagina</b>:
            Het Harmonie model van{" "}
            <a href="http://www.knmi.nl" target="blank">
              KNMI
            </a>
            . Voorspellingen worden alleen verzameld voor locaties waar ook een
            meting van beschikbaar is. De voorspelling is daarom de voorspelling
            voor de locatie van het bijbehorende meetstation en dus niet de
            locatie van de spot.
            <br />
            <ConditionalRender condition={sport === "kite"}>
              <b>Getij-voorspellingen</b>: Het Kuststrook-fijn model van{" "}
              <a
                href="http://www.rijkswaterstaat.nl/"
                target="_blank"
                rel="noreferrer"
              >
                Rijkswaterstaat
              </a>
              . Dit model geeft de getijdehoogte en stroming met een combinatie
              van astronomisch getij en wind-invloeden volgens het Harmonie
              model van KNMI. De stroming tegen de wind in is een combinatie van
              de stromingsinformatie uit het kuststrook-fijn model met de
              windrichting uit het harmonie model. De stroomvector
              (kuststrook-fijn) wordt ontbonden in een component tegen de wind
              (harmonie) in en een component haaks op de wind. De component
              haaks op de wind wordt vervolgens weg gelaten omdat deze niet of
              nauwelijks van invloed is op de kwaliteit van een kitesessie.
              <br />
            </ConditionalRender>
          </p>
        </div>
      )}
    </div>
  );
};
