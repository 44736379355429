import React, { ChangeEvent } from "react";

interface SportProperties {
  sport: string;
  setSport: any; // TODO: type this
}

export const SportSetting: React.FC<SportProperties> = ({
  sport,
  setSport,
}) => {
  const onSportChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSport(e.target.value);
  };

  return (
    <div onChange={onSportChange}>
      <input
        type="radio"
        value="soar"
        defaultChecked={sport === "soar"}
        name="windUnits"
        id="soarBtn"
      />
      <label htmlFor="soarBtn"> Soar</label>

      <input
        type="radio"
        value="kite"
        defaultChecked={sport === "kite"}
        name="windUnits"
        id="kiteBtn"
      />
      <label htmlFor="kiteBtn"> Kite</label>
    </div>
  );
};
