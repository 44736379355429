import React from "react";

import {
  formatDate,
  localDateNow,
  localDateTimeFromEpoch,
  onlyUniqueValues,
  spotInfoLinkLocation,
} from "../util/util";
import { Reel } from "@bedrock-layout/primitives";
import { Link } from "react-router-dom";
import { Marker } from "../datastructures";
import { useStaticTableDataFromRemote } from "../util/useStaticTableDataFromRemote";

interface SpotInfoLinkProperties {
  spotId: number;
  iconNr: number;
  timestampSecs: number;
}

const SpotInfoLink: React.FC<SpotInfoLinkProperties> = ({
  spotId,
  iconNr,
  timestampSecs,
}) => (
  <Link
    to={spotInfoLinkLocation(spotId, iconNr, timestampSecs)}
    key={"spotlink_" + spotId + "_" + timestampSecs}
  >
    <img
      src={process.env.PUBLIC_URL + "/images/icons/t_" + iconNr + ".svg"}
      width="30"
      height="30"
      alt=""
    />
  </Link>
);

interface OverviewTableProperties {
  startTimestampSecs: number;
  homespotIds: number[];
  colsToPlot: number;
}

export const OverviewTable: React.FC<OverviewTableProperties> = ({
  startTimestampSecs,
  homespotIds,
  colsToPlot,
}) => {
  const unfilteredTableData =
    useStaticTableDataFromRemote<Marker>("table=mv_markers");
  const tableData = unfilteredTableData.filter((m) =>
    homespotIds.includes(m.spot_id),
  );

  const getSpotNameAndId = (row: Marker) => ({
    spot_name: row.spot_name,
    spot_id: row.spot_id,
  });

  function onlyUnique(value: Marker, index: number, array: Marker[]) {
    const otherIndex = array.findIndex(
      (needle) => needle.spot_name === value.spot_name,
    );
    return otherIndex === index;
  }

  const spots = tableData
    .filter(onlyUnique)
    .map(getSpotNameAndId)
    .sort((a, b) => a.spot_name.localeCompare(b.spot_name));

  const startNow = Math.round(Date.now() / 1000) + 3600 > startTimestampSecs;
  const startWeekday = localDateTimeFromEpoch(startTimestampSecs).weekdayLong;
  const dayNameToday = localDateNow().weekdayLong;
  const startsToday = startWeekday === dayNameToday;

  const endTimestampSecs =
    startTimestampSecs + 3600 * (colsToPlot - (startNow ? 1 : 0));
  const fTime = (timestampSecs: number) => formatDate(timestampSecs, "T");

  //console.log(startNow);
  const times = tableData
    ?.map((row) => row.etime)
    ?.filter(onlyUniqueValues)
    ?.filter(
      (t) =>
        (startNow && t === 0) ||
        (t >= startTimestampSecs && t < endTimestampSecs),
    )
    ?.sort();

  const iconNrs = (spot: number, time: number) =>
    tableData
      ?.filter((d) => d.spot_id === spot && d.etime === time)
      .map((d) => d.iconnr);

  return (
    <>
      <ul>
        <li>
          Voor het selecteren van meer of minder spots zie de{" "}
          <Link to="/settings">instellingen pagina</Link>
        </li>
        <li>
          {startsToday ? (
            "De tabel begint vandaag"
          ) : (
            <>
              De tabel begint <b> {startWeekday} </b>
            </>
          )}
        </li>
      </ul>
      {times && spots ? (
        <Reel>
          <table>
            <thead>
              <tr key="headerrow">
                <th key="empty_cell"></th>
                {times.map((t) => (
                  <th key={t + "header"}>{t === 0 ? "Meting" : fTime(t)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {spots.map((s) => (
                <tr key={"tablerow_" + s.spot_id}>
                  <td key={s.spot_id + "_name"}>
                    <Link
                      key={"spotnamelink_" + s.spot_id}
                      to={"/spotinfo?spot=" + s.spot_id}
                    >
                      {s.spot_name}
                    </Link>
                  </td>
                  {times.map((t) => (
                    <td key={s.spot_id + "_" + t}>
                      {iconNrs(s.spot_id, t).map((i) => (
                        <SpotInfoLink
                          key={"spot_" + s.spot_id + "_" + t + "_" + i}
                          spotId={s.spot_id}
                          iconNr={i}
                          timestampSecs={t}
                        />
                      ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Reel>
      ) : (
        "loading"
      )}
    </>
  );
};
