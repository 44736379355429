import { useDataFromRemote } from "./useDataFromRemote";

/**
 * Hook om statische data van de backend te laden. Vandaar dat er ook geen setter-callback wordt teruggegeven: het spul is
 * readonly.
 * @param urlParams
 */
export function useStaticTableDataFromRemote<S>(urlParams: string): S[] {
  const value = useDataFromRemote<S[]>("/scapi.php?" + urlParams, []);
  // De backend geeft niet altijd goed geformatteerde responses.
  // http://soarcast.nl/sc/scapi.php?table=mv_tide_wind_data&spot_id=10
  // geeft een leeg antwoord, als in: een lege string. Op zich is het logisch dat er voor Ijburg geen
  // getijde-informatie is, maar dat zou mogen leiden tot een lege json-array.
  if (value) {
    return value;
  }
  return [];
}
