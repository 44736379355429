import React, { FC, ReactNode } from "react";

interface ConditionalRenderProps {
  condition: boolean;
  children: ReactNode;
}

const ConditionalRender: FC<ConditionalRenderProps> = ({
  condition,
  children,
}) => {
  if (condition) {
    return <>{children}</>;
  }
  return null;
};

export default ConditionalRender;
