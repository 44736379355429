import { KiteGearSetting } from "../KiteGearSetting";
import { SoarGearSetting } from "../SoarGearSetting";
import { WindUnitSetting } from "../WindUnitSetting";
import { HomespotSetting } from "../HomespotSetting";
import React from "react";
import { updateItem } from "react-use-cookie";
import { KiteGear, SoarGear, SpotData } from "../../datastructures";

interface SoarcastSettingsProperties {
  windUnits: string;
  setWindUnits: updateItem;
  kiteGear: KiteGear;
  setKiteGear: (kg: KiteGear) => void;
  soarGear: SoarGear;
  setSoarGear: (sg: SoarGear) => void;
  spotData: SpotData[];
  homespotIds: number[];
  setHomespotIds: (homespotIds: number[]) => void;
}

export const SoarcastSettings: React.FC<SoarcastSettingsProperties> = ({
  windUnits,
  setWindUnits,
  kiteGear,
  setKiteGear,
  soarGear,
  setSoarGear,
  spotData,
  homespotIds,
  setHomespotIds,
}) => {
  return (
    <div>
      <h1>Instellingen</h1>
      <table>
        <tbody>
          <tr>
            <td>Windsnelheid:</td>
            <td>
              <WindUnitSetting
                windUnits={windUnits}
                setWindUnits={setWindUnits}
              />
            </td>
          </tr>
          <tr>
            <td>Kite gear:</td>
            <td>
              <KiteGearSetting
                kiteGear={kiteGear}
                setKiteGear={setKiteGear}
                windUnits={windUnits}
              />
            </td>
          </tr>
          <tr>
            <td>Soar gear:</td>
            <td>
              <SoarGearSetting
                soarGear={soarGear}
                setSoarGear={setSoarGear}
                windUnits={windUnits}
              />
            </td>
          </tr>
          <tr>
            <td>
              Home spots
              <br /> (voor kalender en overzicht):
            </td>
            <td>
              <HomespotSetting
                spotData={spotData}
                homespotIds={homespotIds}
                setHomespotIds={setHomespotIds}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
