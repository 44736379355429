import { convertWind, friendlyWindUnitName } from "../util/util";
import { Inline } from "@bedrock-layout/inline";
import { SoarGear } from "../datastructures";
import React, { ChangeEvent } from "react";

interface SoarGearSettingProperties {
  soarGear: SoarGear;
  setSoarGear: (sg: SoarGear) => void;
  windUnits: string;
}

export const SoarGearSetting: React.FC<SoarGearSettingProperties> = ({
  soarGear,
  setSoarGear,
  windUnits,
}) => {
  const legalSoarCombo = (soarGear: SoarGear): boolean =>
    soarGear.bigWing || soarGear.smallWing;

  const onBigWingChange = (e: ChangeEvent<HTMLInputElement>) => {
    const copy = Object.assign({}, soarGear);
    copy.bigWing = e.target.checked;
    if (legalSoarCombo(copy)) {
      setSoarGear(copy);
    } else {
      e.target.checked = !e.target.checked;
    }
  };

  const onSmallWingChange = (e: ChangeEvent<HTMLInputElement>) => {
    const copy = Object.assign({}, soarGear);
    copy.smallWing = e.target.checked;
    if (legalSoarCombo(copy)) {
      setSoarGear(copy);
    } else {
      e.target.checked = !e.target.checked;
    }
  };

  const displayWindUnits = friendlyWindUnitName(windUnits);
  const displayWind = (windInMs: number) => convertWind(windInMs, windUnits);

  return (
    <div>
      <Inline gutter="lg" align="center">
        <input
          type="checkbox"
          id="bigWing"
          name="bigWing"
          value="Bergscherm"
          defaultChecked={soarGear.bigWing}
          onChange={onBigWingChange}
        />{" "}
        <img
          src={process.env.PUBLIC_URL + "/images/icons/t_16.svg"}
          width="30"
          height="30"
          alt="Bergscherm"
        />
        Bergscherm (aan een mooi duin {displayWind(5)} tm {displayWind(8)}{" "}
        {displayWindUnits} en max 30 graden cross){" "}
      </Inline>
      <Inline gutter="lg" align="center">
        <input
          type="checkbox"
          id="smallWing"
          name="smallWing"
          value="Miniwing"
          defaultChecked={soarGear.smallWing}
          onChange={onSmallWingChange}
        />{" "}
        <img
          src={process.env.PUBLIC_URL + "/images/icons/t_32.svg"}
          width="30"
          height="30"
          alt="Miniwing"
        />
        Miniwing (aan een mooi duin {displayWind(6)} tm {displayWind(12)}{" "}
        {displayWindUnits} en max 25 graden cross) <br />
      </Inline>
    </div>
  );
};
