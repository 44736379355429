import { convertWind, friendlyWindUnitName } from "../util/util";
import { Inline } from "@bedrock-layout/inline";

import { KiteGear } from "../datastructures";
import React, { ChangeEvent } from "react";

interface KiteGearSettingProperties {
  kiteGear: KiteGear;
  setKiteGear: (sg: KiteGear) => void;
  windUnits: string;
}

export const KiteGearSetting: React.FC<KiteGearSettingProperties> = ({
  kiteGear,
  setKiteGear,
  windUnits,
}) => {
  const legalKiteCombo = (kiteGear: KiteGear) =>
    (kiteGear.medKite || !kiteGear.smallKite || !kiteGear.bigKite) &&
    (kiteGear.bigKite || kiteGear.smallKite || kiteGear.medKite);

  const onBigKiteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const copy = Object.assign({}, kiteGear);
    copy.bigKite = e.target.checked;
    if (legalKiteCombo(copy)) {
      setKiteGear(copy);
    } else {
      e.target.checked = !e.target.checked;
    }
  };
  const onMedKiteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const copy = Object.assign({}, kiteGear);
    copy.medKite = e.target.checked;
    if (legalKiteCombo(copy)) {
      setKiteGear(copy);
    } else {
      e.target.checked = !e.target.checked;
    }
  };
  const onSmallKiteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const copy = Object.assign({}, kiteGear);
    copy.smallKite = e.target.checked;
    if (legalKiteCombo(copy)) {
      setKiteGear(copy);
    } else {
      e.target.checked = !e.target.checked;
    }
  };

  const displayWindUnits = friendlyWindUnitName(windUnits);
  const displayWind = (windInMs: number) => convertWind(windInMs, windUnits);

  return (
    <div>
      <Inline gutter="lg" align="center">
        <input
          type="checkbox"
          id="bigKite"
          name="bigKite"
          value="Licht weer kite"
          checked={kiteGear.bigKite}
          onChange={onBigKiteChange}
        />{" "}
        <img
          src={process.env.PUBLIC_URL + "/images/icons/t_1.svg"}
          width="30"
          height="30"
          alt="Licht weer kite"
        />
        <>
          Licht weer kite ({displayWind(7)} tm {displayWind(10)}{" "}
          {displayWindUnits}){" "}
        </>
      </Inline>

      <Inline gutter="lg" align="center">
        <input
          type="checkbox"
          id="medKite"
          name="medKite"
          value="Midden kite"
          defaultChecked={kiteGear.medKite}
          onChange={onMedKiteChange}
        />{" "}
        <img
          src={process.env.PUBLIC_URL + "/images/icons/t_2.svg"}
          width="30"
          height="30"
          alt="Midden kite"
        />
        Midden kite ({displayWind(9)} tm {displayWind(15)} {displayWindUnits}){" "}
      </Inline>

      <Inline gutter="lg" align="center">
        <input
          type="checkbox"
          id="smallKite"
          name="smallKite"
          value="Storm kite"
          defaultChecked={kiteGear.smallKite}
          onChange={onSmallKiteChange}
        />{" "}
        <img
          src={process.env.PUBLIC_URL + "/images/icons/t_4.svg"}
          width="30"
          height="30"
          alt="Midden kite"
        />
        Storm kite ({displayWind(13)} tm {displayWind(20)} {displayWindUnits}){" "}
      </Inline>
    </div>
  );
};
