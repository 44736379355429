import {
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { Scatter } from "react-chartjs-2";
import { graphHeight } from "../util/util";
import React from "react";
import { SpotData, TideWindData, WindowSize } from "../datastructures";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

ChartJS.defaults.font.size = 12;

interface TideHeightGraphProperties {
  day: string;
  windowSize: WindowSize;
  tideWindData: TideWindData[];
  selectedSpotData: SpotData;
}

export const TideHeightGraph: React.FC<TideHeightGraphProperties> = ({
  day,
  windowSize,
  tideWindData,
  selectedSpotData,
}) => {
  const sepGraphDataSet = tideWindData.map((row) => ({
    x: row["validtime"] * 1000,
    y: Math.round(row["sep"] * 100),
  }));

  const options: ChartOptions<"scatter"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: "Waterhoogte boven zeeniveau (centimeter)",
        },
        beginAtZero: true,
      },
      x: {
        type: "time",
        bounds: "data",
        time: {
          displayFormats: {
            hour: "H:mm",
            minute: "H:mm",
          },
          tooltipFormat: "H:mm",
        },
      },
    },
    plugins: {
      title: {
        text:
          "Waterhoogte boven zee niveau " +
          selectedSpotData["name"] +
          " " +
          day,
        display: true,
      },
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: function (context: any) {
            return context.label + ": " + context.parsed.y + " centimeter";
          },
        },
      },
    },
  };

  const sepGraphData = sepGraphDataSet
    ? {
        datasets: [
          {
            backgroundColor: "rgba(35, 134, 239, 0)",
            borderColor: "rgba(35, 134, 239, 1)",
            data: sepGraphDataSet,
            label: "Waterhoogte",
            pointHitRadius: 10,
            pointRadius: 1,
            showLine: true,
          },
        ],
      }
    : null;

  return (
    <>
      <br />

      <div
        style={{
          height: graphHeight(windowSize),
          position: "relative",
        }}
      >
        {sepGraphData != null ? (
          <Scatter options={options} data={sepGraphData} />
        ) : (
          "Loading tide graph data"
        )}
      </div>
    </>
  );
};
