import { Link } from "react-router-dom";

import { spotInfoLinkLocation } from "../util/util";
import React from "react";
import { Marker } from "../datastructures";

interface MapsMarkerIconProperties {
  key: string;
  marker: Marker;
  lat: number;
  lng: number;
}

export const MapsMarkerIcon: React.FC<MapsMarkerIconProperties> = ({
  marker,
}) => {
  const kite = marker.bigkite || marker.medkite || marker.smallkite;

  const xOffset =
    -17 + (marker.nrofmarkersatspot === 1 ? 0 : kite ? 2 : -2) * 3;
  const yOffset =
    -17 + (marker.nrofmarkersatspot === 1 ? 0 : kite ? -1 : 1) * 3;

  const linkTarget = spotInfoLinkLocation(
    marker.spot_id,
    marker.iconnr,
    marker.etime,
  );

  return (
    <Link to={linkTarget}>
      <img
        alt={marker.spot_name}
        src={
          process.env.PUBLIC_URL + "/images/icons/t_" + marker.iconnr + ".svg"
        }
        width="35"
        height="35"
        style={{
          position: "relative",
          top: xOffset + "px",
          left: yOffset + "px",
        }}
      />
    </Link>
  );
};
