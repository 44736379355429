import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { SC_BASE_PATH } from "./util";
export function fetchRemoteData<T>(
  url: string,
  controller: AbortController
): Promise<T | null> {
  return axios
    .get<T>(url, {
      signal: controller.signal,
      validateStatus: (status) => status === 200, // accepteer alleen OK responses
    })
    .then((response: AxiosResponse<T>) => {
      // const contentType = response.headers['content-type'];
      // if (!contentType?.startsWith("application/json")) {
      //     throw new Error("Error while fetching data from " + url + ". Content type is not application/json. Response: " + response);
      // }

      return response.data;
    })
    .catch((thrown) => {
      if (!axios.isCancel(thrown)) {
        throw new Error(
          "Error while fetching data from " + url + ": " + thrown
        );
      }
      return null;
    });
}

function fetchRemoteDataInternal<L, R>(
  uri: string,
  setter: Dispatch<SetStateAction<L | null>>,
  transformer?: (remote: R) => L | null
) {
  const controller = new AbortController();
  const url = SC_BASE_PATH + uri;
  fetchRemoteData<R>(url, controller)
    .then((data: R | null) => {
      if (!data) {
        return null;
      }
      if (transformer) {
        return transformer(data);
      }

      return data as unknown as L;
    })
    .then((data: L | null) => {
      setter(data);
    });

  return () => controller.abort();
}

export function useDataFromRemote<L, R = L>(
  uri: string,
  defaultValue: L | null,
  transformer?: (remote: R) => L | null
): L | null {
  const [data, setData] = useState<L | null>(defaultValue);

  useEffect(() => {
    return fetchRemoteDataInternal(uri, setData, transformer);
  }, [uri, transformer]);

  return data;
}
