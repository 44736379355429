import { FC } from "react";

interface UnicodeSmileyFontProps {
  smiley: string;
}

const UnicodeSmileyFont: FC<UnicodeSmileyFontProps> = ({ smiley }) => {
  const smileyStyle = {
    fontFamily:
      "'Segoe UI Symbol', 'Apple Color Emoji', 'Noto Color Emoji', 'Twemoji', 'Segoe UI', sans-serif",
  };

  return <span style={smileyStyle}>{smiley}</span>;
};

export default UnicodeSmileyFont;
