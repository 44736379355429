import DOMPurify from "dompurify";
import React from "react";

interface SanitizedDynamicHtmlProperties {
  dynamicUnsafeHtml: string;
}

export const SanitizedDynamicHtml: React.FC<SanitizedDynamicHtmlProperties> = ({
  dynamicUnsafeHtml,
}) => {
  return dynamicUnsafeHtml ? (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(dynamicUnsafeHtml),
        }}
      />
    </>
  ) : (
    <></>
  );
};
