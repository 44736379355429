import useCookie, { cookieOptions } from "react-use-cookie";

export const SOARCAST_COOKIE_CONFIG: cookieOptions = {
  days: 730,
  SameSite: "Strict",
};

/**
 * Deze hook maakt een state variabele persistent zodat de waarde bewaard blijft voor de volgende keer dat je de applicatie gebruikt.
 * De waarde moet als een string worden geformatteerd om te kunnen worden opgeslagen, gebruik daarvoor de encoder/decoder parameters.
 *
 * @param name De naam waarmee de variabele wordt opgeslagen.
 * @param encoder to-string transformer.
 * @param decoder from-string transformer.
 * @param defaultValue Initial / default waarde.
 */
export function usePersistentState<S>(
  name: string,
  encoder: (value: S) => string,
  decoder: (s: string) => S,
  defaultValue: S,
): [S, (newValue: S) => void] {
  const [value, setter] = useCookie(name, encoder(defaultValue));

  function encodingSetter(newValue: S): void {
    setter(encoder(newValue), SOARCAST_COOKIE_CONFIG);
  }

  let decodedValue;
  try {
    decodedValue = decoder(value);
  } catch (e) {
    decodedValue = defaultValue;
  }

  return [decodedValue, encodingSetter];
}
