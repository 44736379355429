import { MapOptions } from "google-map-react";

export const MAP_OPTIONS: MapOptions = {
  mapTypeControl: true,
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          hue: "#00fff3",
        },
        {
          weight: "1.63",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          weight: "0.01",
        },
        {
          color: "#3ebfb9",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "all",
      stylers: [
        {
          color: "#e0efef",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "all",
      stylers: [
        {
          color: "#e0efef",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "all",
      stylers: [
        {
          color: "#e0efef",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "all",
      stylers: [
        {
          color: "#e2e2e2",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#7dcdcc",
        },
      ],
    },
  ],
};
