import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { MapsMarkerIcon } from "../MapsMarkerIcon";
import { MAP_OPTIONS } from "../MapOptions";
import { Slider } from "@mui/material";
import { formatDate, onlyUniqueValues } from "../../util/util";
import { Marker, SpotData, WindowSize } from "../../datastructures";
import { useStaticTableDataFromRemote } from "../../util/useStaticTableDataFromRemote";

interface SoarcastMapProperties {
  spotData: SpotData[];
  windowSize: WindowSize;
}

export const SoarcastMap: React.FC<SoarcastMapProperties> = ({
  spotData,
  windowSize,
}) => {
  const [etimeToShow, setEtimeToShow] = useState(0);

  const defaultProps = {
    center: { lat: 52.458, lng: 4.548 },
    zoom: 8,
  };
  const unfilteredTableData =
    useStaticTableDataFromRemote<Marker>("table=mv_markers");
  //const startNow = Math.round(Date.now() / 1000) + 3600 > startTimestampSecs;
  const nowTimestampSecs = Math.round(Date.now() / 1000);
  const endTimestampSecs = nowTimestampSecs + 3600 * 24;
  const fTime = (timestampSecs: number): string =>
    formatDate(timestampSecs, "T");

  const spotDataForSpot = (spotId: number): SpotData | undefined =>
    spotData.find((s) => s.id === spotId);

  const times = unfilteredTableData
    ?.map((row) => row.etime)
    ?.filter(onlyUniqueValues)
    ?.filter((t) => t === 0 || (t >= nowTimestampSecs && t < endTimestampSecs))
    ?.sort();

  const fake0Time = times ? times[1] - 3600 : 0;
  const fake0Times = times?.map((t) => (t === 0 ? fake0Time : t));
  const formattedTime = (t: number) =>
    fake0Times ? (t === fake0Times[0] ? "Meting" : fTime(t)) : 0;

  const marks = fake0Times
    ?.map((t) => ({
      value: t,
      label: formattedTime(t),
    }))
    .filter((element, index) => index % 3 === 0);

  //Slider docs see https://mui.com/material-ui/api/slider/

  const markersForTime = unfilteredTableData
    .filter(
      (m) =>
        spotDataForSpot(m.spot_id)?.kite || spotDataForSpot(m.spot_id)?.soar,
    )
    ?.filter((m) => m.etime === etimeToShow);

  const onSliderValChange = (event: Event, newValue: number | number[]) => {
    const v = Array.isArray(newValue) ? newValue[0] : newValue;
    setEtimeToShow(v === fake0Time ? 0 : v);
  };

  return (
    <>
      <div style={{ padding: "0 2rem" }}>
        <Slider
          aria-label="Custom marks"
          defaultValue={0}
          min={fake0Times ? fake0Times[0] : 0}
          max={endTimestampSecs}
          getAriaValueText={(val) => fTime(val)}
          valueLabelFormat={formattedTime}
          getAriaLabel={fTime}
          step={3600}
          valueLabelDisplay="on"
          marks={marks}
          onChange={onSliderValChange}
        />
      </div>
      {/* </div> */}
      {/* Important! Always set the container height explicitly */}
      <div style={{ height: windowSize.innerHeight - 110, width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAQ3Az1bVB2BMs6krg0siVx88hEh7DiXi4" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={MAP_OPTIONS}
        >
          {markersForTime ? (
            markersForTime.map((m) => (
              <MapsMarkerIcon
                key={"spot_" + m.spot_id + "_" + m.etime + "_" + m.iconnr}
                lat={m.n}
                lng={m.e}
                marker={m}
              />
            ))
          ) : (
            <></>
          )}
        </GoogleMapReact>
      </div>
    </>
  );
};
