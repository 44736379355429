import React, { useContext } from "react";
import { SpeedContext } from "../util/Contexts";
import { convertWind, friendlyWindUnitName } from "../util/util";

interface SpeedProperties {
  value: number | undefined | null;
  fractionDigits?: number | undefined | null;
}

export const Speed: React.FC<SpeedProperties> = ({ value, fractionDigits }) => {
  const digits = fractionDigits || 1;
  const speedUnit = useContext(SpeedContext);
  const displayValue =
    value && speedUnit ? convertWind(value, speedUnit, digits) : "-";
  const displayUnit = friendlyWindUnitName(speedUnit).toLocaleLowerCase();
  return (
    <span>
      {displayValue} {displayUnit}
    </span>
  );
};
