import React, { useEffect, useState } from "react";
import {
  formatDateFlexLength,
  localDateTimeFromEpoch,
  SC_BASE_PATH,
} from "../util/util";
import { fetchRemoteData } from "../util/useDataFromRemote";
import { DateTime } from "luxon";
import { Speed } from "./Speed";
import { Direction } from "./Direction";

interface RemoteMeting {
  let_id: number;
  etime: number;
  spot_id: number;
  spot_name: string;
  et_name: string; // Windstoot, Windrichting, Windsnelheid
  units: string; // m/s, graden
  evalue: number;
}

class Meting {
  constructor(
    public moment: DateTime,
    public windsnelheid: number | undefined,
    public windstoten: number | undefined,
    public windrichting: number | undefined
  ) {}
}

function useActueleWindMeting(spotId: number): Meting | null {
  const [actueleMeting, setActueleMeting] = useState<Meting | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    const url = SC_BASE_PATH + "/scapi.php?table=mv_spotwind&spot_id=" + spotId;
    fetchRemoteData<RemoteMeting[]>(url, controller)
      .then((data: RemoteMeting[] | null) => {
        if (data?.length === 3) {
          return new Meting(
            localDateTimeFromEpoch(data[0].etime),
            data.find((c) => c.et_name === "Windsnelheid")?.evalue,
            data.find((c) => c.et_name === "Windstoot")?.evalue,
            data.find((c) => c.et_name === "Windrichting")?.evalue
          );
        }

        return null;
      })
      .then((meting: Meting | null) => {
        setActueleMeting(meting);
      });
    return () => controller.abort();
  }, [spotId]);

  return actueleMeting;
}

interface ActueleWindMetingProperties {
  spotId: number;
}

function teOud(meting: Meting): boolean {
  return meting.moment.diffNow().as("minute") > 35;
}

export const ActueleWindMeting: React.FC<ActueleWindMetingProperties> = ({
  spotId,
}) => {
  const meting = useActueleWindMeting(spotId);
  if (meting == null || teOud(meting)) {
    return null;
  }

  return (
    <>
      Laatste meting: {formatDateFlexLength(meting?.moment)}
      <ul>
        <li>
          Windsnelheid: <Speed value={meting?.windsnelheid} />
        </li>
        <li>
          Windstoten: <Speed value={meting?.windstoten} />
        </li>
        <li>
          Windrichting: <Direction value={meting?.windrichting} />
        </li>
      </ul>
    </>
  );
};
