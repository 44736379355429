import React from "react";

import "chartjs-adapter-luxon";

import { relDaysFromName } from "../util/util";
import { TideHeightGraph } from "./TideHeightGraph";
import { UpwindCurrVelocityGraph } from "./UpwindCurrVelocityGraph";
import { DateTime } from "luxon";
import { SpotData, TideWindData, WindowSize } from "../datastructures";

interface TideGraphsProperties {
  spot: number;
  tideWindData: TideWindData[];
  day: string;
  windowSize: WindowSize;
  selectedSpotData: SpotData;
  duringDayOnly: boolean;
  windUnits: string;
}

export const TideGraphs: React.FC<TideGraphsProperties> = ({
  spot,
  tideWindData,
  day,
  windowSize,
  selectedSpotData,
  duringDayOnly,
  windUnits,
}) => {
  const spotHasTideInfo =
    selectedSpotData == null ? false : !!selectedSpotData["kf_n"];

  const midnight = DateTime.local({ zone: "Europe/Amsterdam" })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .plus({ days: relDaysFromName(day) });

  const dataStart = midnight.plus({ hours: duringDayOnly ? 7 : 0 });
  const dataEnd = midnight
    .plus({ days: 1 })
    .minus({ hours: duringDayOnly ? 5 : 0 });

  const filteredTideWindData = tideWindData.filter(
    (row) =>
      row["validtime"] >= dataStart.toUnixInteger() &&
      row["validtime"] <= dataEnd.toUnixInteger()
  );

  return (
    <>
      {tideWindData?.length ? (
        <>
          <UpwindCurrVelocityGraph
            day={day}
            windowSize={windowSize}
            tideWindData={filteredTideWindData}
            selectedSpotData={selectedSpotData}
            windUnits={windUnits}
          />

          <TideHeightGraph
            day={day}
            windowSize={windowSize}
            tideWindData={filteredTideWindData}
            selectedSpotData={selectedSpotData}
          />
        </>
      ) : (
        <>{spotHasTideInfo ? "Loading tide graphs data" : ""}</>
      )}
    </>
  );
};
