import { useSearchParams } from "react-router-dom";

export function useQueryParam(
  name: string,
): [string | null, (value: string) => void] {
  const [searchValue, setSearchValue] = useSearchParams();
  const queryParamValue = searchValue.get(name);

  const setParamValue = (value: string) => {
    // TODO take care of immutability
    searchValue.set(name, value);
    setSearchValue(searchValue);
  };

  return [queryParamValue, setParamValue];
}
