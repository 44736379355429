import React from "react";

interface DirectionProperties {
  value: number | undefined | null;
}

export const Direction: React.FC<DirectionProperties> = ({ value }) => {
  const displayValue = value || "-";

  return <span>{displayValue} graden</span>;
};
