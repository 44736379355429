import { DateTime } from "luxon";

export function friendlyWindUnitName(unit: string): string {
  switch (unit) {
    case "kts":
      return "Knopen";
    case "kmh":
      return "Kilometer per uur";
    case "ms":
      return "Meters per seconde";
    case "deg":
      return "Graden";
    default:
      throw new Error("Unknown unit " + unit);
  }
}

export function unitConversionFactor(destinationUnit: string): number {
  switch (destinationUnit) {
    case "kts":
      return 1.943844;
    case "kmh":
      return 3.6;
    case "ms":
      return 1;
    default:
      throw new Error("Unknown unit " + destinationUnit);
  }
}

export const convertWind = (
  windInMs: number,
  destinationUnit: string,
  fractionDigits: number = 0
): number => {
  const factor = Math.pow(10, fractionDigits);
  return (
    Math.round(convertCurrent(windInMs, destinationUnit) * factor) / factor
  );
};

export const convertCurrent = (
  currentInMs: number,
  destinationUnit: string
): number => currentInMs * unitConversionFactor(destinationUnit);

export function onlyUniqueValues(value: any, index: any, self: any[]) {
  return self.indexOf(value) === index;
}

export const localDateTimeFromEpoch = (validTime: number) =>
  DateTime.fromSeconds(validTime).setLocale("nl");

export const formatDate = (validTime: number, formatStr: string): string =>
  localDateTimeFromEpoch(validTime).toFormat(formatStr);

export const localDateNow = () => DateTime.now().setLocale("nl");

export const SC_BASE_PATH = "/sc";

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;
const portStr = port === "" || port === "80" ? "" : ":" + port;
export const SC_BASE_URL = `${protocol}//${hostname}${portStr}${SC_BASE_PATH}`;

export const clone = (objectToClone: any): any =>
  JSON.parse(JSON.stringify(objectToClone));

export const portrait = (windowSize: {
  innerHeight: number;
  innerWidth: number;
}) => windowSize.innerHeight > windowSize.innerWidth;
export const nonMenuHeight = (windowSize: {
  innerHeight: number;
  innerWidth: number;
}) => windowSize.innerHeight - 110;
// export const graphHeight = (windowSize) =>
//   windowSize
//     ? portrait(windowSize)
//       ? nonMenuHeight(windowSize) / 2
//       : nonMenuHeight(windowSize)
//     : 100;

export const graphHeight = (windowSize: {
  innerHeight: number;
  innerWidth: number;
}) =>
  windowSize
    ? portrait(windowSize)
      ? windowSize.innerHeight / 2
      : nonMenuHeight(windowSize) > 300
      ? nonMenuHeight(windowSize)
      : windowSize.innerHeight
    : 100;

export function convertRemToPixels(rem: number): number {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function relNames(relDays: number) {
  switch (relDays) {
    case -2:
      return "daybeforeyesterday";
    case -1:
      return "yesterday";
    case 0:
      return "today";
    case 1:
      return "tomorrow";
    case 2:
      return "dayaftertomorrow";
    default:
      return new Error("Unable to make date diff relative");
  }
}

export function relDaysFromName(relativeDayName: string): number {
  switch (relativeDayName) {
    case "daybeforeyesterday":
      return -2;
    case "yesterday":
      return -1;
    case "today":
      return 0;
    case "tomorrow":
      return 1;
    case "dayaftertomorrow":
      return 2;
    default:
      throw new Error("Unsupported relativeDayName " + relativeDayName);
  }
}

export function relativeDateName(timestampSecs: number) {
  if (timestampSecs === 0) {
    return relNames(0);
  }
  const nowTimestampSecs = Math.round(Date.now() / 1000);
  const nowDate = localDateTimeFromEpoch(nowTimestampSecs);

  const eventDate = localDateTimeFromEpoch(timestampSecs);

  const weekdayDiff = eventDate.weekday - nowDate.weekday;
  const modedDiff =
    weekdayDiff < -4
      ? weekdayDiff + 7
      : weekdayDiff > 4
      ? weekdayDiff - 7
      : weekdayDiff;

  return relNames(modedDiff);
}

export const isToday = function (someDay: DateTime): boolean {
  return someDay.hasSame(DateTime.now(), "day");
};

export const formatDateFlexLength = function (date: DateTime): string {
  return isToday(date)
    ? date.toLocaleString({
        timeStyle: "short",
      })
    : date.toLocaleString({
        dateStyle: "full",
        timeStyle: "short",
      });
};

export function spotInfoLinkLocation(
  spotId: number,
  iconNr: number,
  timestampSecs: number
): string {
  const sport = iconNr < 16 ? "kite" : "soar";
  const sportParam = iconNr ? "&sport=" + sport : "";
  const dayParam = "&day=" + relativeDateName(timestampSecs);
  return "/spotinfo?spot=" + spotId + sportParam + dayParam;
}

export function getSportFromHostname(): string {
  if (process.env.REACT_APP_VERSION === "DEVELOPMENT") {
    return process.env.REACT_APP_SPORT || "kite";
  }
  return window.location?.toString()?.toLowerCase()?.includes("soar")
    ? "soar"
    : "kite";
}

export function roundPlaces(value: number, places: number): number {
  return +(Math.round(Number(value + "e+" + places)) + "e-" + places);
}

export function roundTowardZero(num: number): number {
  return num >= 0 ? Math.floor(num) : Math.ceil(num);
}
