import {
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { Scatter } from "react-chartjs-2";
import {
  convertCurrent,
  friendlyWindUnitName,
  graphHeight,
} from "../util/util";
import { SpotData, TideWindData, WindowSize } from "../datastructures";
import React from "react";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

ChartJS.defaults.font.size = 12;

const round2 = (num: number): number =>
  Math.round(num * 100 + Number.EPSILON) / 100;

interface UpwindCurrVelocityGraphProperties {
  day: string;
  windowSize: WindowSize;
  tideWindData: TideWindData[];
  selectedSpotData: SpotData;
  windUnits: string;
}

export const UpwindCurrVelocityGraph: React.FC<
  UpwindCurrVelocityGraphProperties
> = ({ day, windowSize, tideWindData, selectedSpotData, windUnits }) => {
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        suggestedMin: convertCurrent(0.5144444, windUnits),
        suggestedMax: convertCurrent(0.5144444, windUnits),
        distribution: "linear",
        title: {
          display: true,
          text:
            "Stroomsnelheid recht tegen de wind in (" +
            friendlyWindUnitName(windUnits) +
            ")",
        },

        ticks: {
          beginAtZero: true,
          min: -1,
          max: 1,
        },

        grid: {
          color: function (context: any) {
            if (context.tick.value > 0) {
              return CHART_COLORS.green;
            } else if (context.tick.value < 0) {
              return CHART_COLORS.red;
            }

            return "#000000";
          },
        },
      },
      x: {
        type: "time",
        title: {
          display: true,
          text: "Tijd",
        },
        distribution: "linear",
        bounds: "data",
        time: {
          displayFormats: {
            hour: "H:mm",
            minute: "H:mm",
          },
          tooltipFormat: "H:mm",
        },
        ticks: {
          beginAtZero: false,
          maxRotation: 0,
        },
      },
    },
    plugins: {
      title: {
        text:
          "Stroomsnelheid tegen de wind in " +
          selectedSpotData["name"] +
          " " +
          day,
        display: true,
      },
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: function (context: any) {
            return (
              context.label +
              ": " +
              context.parsed.y +
              " " +
              friendlyWindUnitName(windUnits)
            );
          },
        },
      },
    },
  };

  const graphDataSet = tideWindData?.map((row) => ({
    x: row["validtime"] * 1000,
    y: round2(convertCurrent(row["upwind_cur_speed"], windUnits)),
  }));

  const graphData = graphDataSet
    ? {
        datasets: [
          {
            backgroundColor: "rgba(35, 134, 239, 0)",
            borderColor: "rgba(35, 134, 239, 1)",
            data: graphDataSet,
            label: "Stroomsnelheid recht tegen de wind in",

            pointHitRadius: 10,
            pointRadius: 1,
            showLine: true,
          },
        ],
      }
    : null;

  return (
    <>
      <br />

      <div
        style={{
          height: graphHeight(windowSize),
          position: "relative",
        }}
      >
        {graphData != null ? (
          <Scatter options={options} data={graphData} />
        ) : (
          "Loading tide graph data"
        )}
      </div>
    </>
  );
};
