import React, { createContext } from "react";

interface AppContextData {
  dayState?: string;
  setDayState?: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = createContext<AppContextData>({});

export const SpeedContext = createContext("kts");
