import React, { useEffect, useState } from "react";
import "./index.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Menu } from "./Menu";
import { QueryParamProvider } from "use-query-params";
import { SoarcastMap } from "./components/pages/SoarcastMap";
import { Overview } from "./components/pages/Overview";
import { Calendar } from "./components/pages/Calendar";
import { Over } from "./components/pages/Over";
import { SpotInfo } from "./components/pages/SpotInfo";
import { SoarcastSettings } from "./components/pages/SoarcastSettings";
import { LocationInfo } from "./components/pages/LocationInfo";
import { AppBoundary } from "@bedrock-layout/primitives";
import { AppContext, SpeedContext } from "./util/Contexts";
import {
  ActivityData,
  ActivityLimits,
  ExtraGraphsData,
  GraphsData,
  KiteGear,
  LocationData,
  SoarGear,
  SpotData,
  WindowSize,
} from "./datastructures";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { identity } from "lodash";
import { useStaticTableDataFromRemote } from "./util/useStaticTableDataFromRemote";
import { usePersistentState } from "./util/usePersistentState";

const MAX_WIDTH = 1199;

function getWindowSize(): WindowSize {
  // AppBoundary large is 1199 pixels wide
  const innerWidth = Math.min(window.innerWidth, MAX_WIDTH);
  const innerHeight = window.innerHeight;

  return new WindowSize(innerWidth, innerHeight);
}

export function App(): React.JSX.Element {
  // Static data
  const locationData = useStaticTableDataFromRemote<LocationData>(
    "table=location_gps&harm=true"
  );

  const spotData = useStaticTableDataFromRemote<SpotData>("table=spot");
  const activitiesData =
    useStaticTableDataFromRemote<ActivityData>("table=activity");
  const graphsData = useStaticTableDataFromRemote<GraphsData>(
    "table=view_graphs_per_activity"
  );
  const extraGraphsData = useStaticTableDataFromRemote<ExtraGraphsData>(
    "table=mv_act_extra_info"
  );
  const actLimitData = useStaticTableDataFromRemote<ActivityLimits>(
    "table=mv_activity_limits"
  );

  // User preferences
  const [windUnits, setWindUnits] = usePersistentState<string>(
    "windUnits",
    identity,
    identity,
    "kts"
  );
  const [kiteGear, setKiteGear] = usePersistentState<KiteGear>(
    "kiteGear",
    JSON.stringify,
    JSON.parse,
    new KiteGear()
  );
  const [soarGear, setSoarGear] = usePersistentState<SoarGear>(
    "soarGear",
    JSON.stringify,
    JSON.parse,
    new SoarGear()
  );
  const [homespotIds, setHomespotIds] = usePersistentState<number[]>(
    "homespots",
    JSON.stringify,
    JSON.parse,
    spotData.map((s) => s.id)
  );

  const [dayState, setDayState] = useState<string>("today");
  const [duringDayOnly, setDuringDayOnly] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <AppContext.Provider value={{ dayState, setDayState }}>
      <SpeedContext.Provider value={windUnits}>
        <AppBoundary boundarySize={MAX_WIDTH}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <Menu />
              <Routes>
                <Route
                  path="/kaart"
                  element={
                    spotData && windowSize ? (
                      <SoarcastMap
                        spotData={spotData}
                        windowSize={windowSize}
                      />
                    ) : (
                      "Loading"
                    )
                  }
                />

                <Route
                  path="/overzicht"
                  element={
                    windowSize ? (
                      <Overview
                        homespotIds={homespotIds}
                        windowSize={windowSize}
                      />
                    ) : (
                      "Loading"
                    )
                  }
                />

                <Route
                  path="/kalender"
                  element={
                    spotData?.length ? (
                      <Calendar
                        kiteGear={kiteGear}
                        soarGear={soarGear}
                        spotData={spotData}
                        homespotIds={homespotIds}
                      />
                    ) : (
                      "Loading"
                    )
                  }
                />
                <Route path="/over" element={<Over />} />

                <Route
                  path="/spotinfo"
                  element={
                    spotData?.length &&
                    windUnits &&
                    kiteGear &&
                    soarGear &&
                    activitiesData?.length &&
                    graphsData?.length &&
                    extraGraphsData?.length &&
                    actLimitData?.length &&
                    windowSize ? (
                      <SpotInfo
                        spotData={spotData}
                        locationData={locationData}
                        windUnits={windUnits}
                        kiteGear={kiteGear}
                        soarGear={soarGear}
                        activitiesData={activitiesData}
                        graphsData={graphsData}
                        extraGraphsData={extraGraphsData}
                        actLimitData={actLimitData}
                        duringDayOnly={duringDayOnly}
                        setDuringDayOnly={setDuringDayOnly}
                        windowSize={windowSize}
                      />
                    ) : (
                      "Loading"
                    )
                  }
                />

                <Route
                  path="/locationinfo"
                  element={
                    windUnits && locationData?.length && windowSize ? (
                      <LocationInfo
                        windUnits={windUnits}
                        locationData={locationData}
                        duringDayOnly={duringDayOnly}
                        setDuringDayOnly={setDuringDayOnly}
                        windowSize={windowSize}
                      />
                    ) : (
                      "Loading"
                    )
                  }
                />

                <Route
                  path="/settings"
                  element={
                    spotData?.length ? (
                      <SoarcastSettings
                        windUnits={windUnits}
                        setWindUnits={setWindUnits}
                        kiteGear={kiteGear}
                        setKiteGear={setKiteGear}
                        soarGear={soarGear}
                        setSoarGear={setSoarGear}
                        spotData={spotData}
                        homespotIds={homespotIds}
                        setHomespotIds={setHomespotIds}
                      />
                    ) : (
                      "Loading"
                    )
                  }
                />

                <Route path="/" element={<Navigate to="/kaart" />} />
              </Routes>
            </QueryParamProvider>
          </BrowserRouter>
        </AppBoundary>
      </SpeedContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
