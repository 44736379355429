import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { getSportFromHostname } from "./util/util";
import { BubbleController, Chart } from "chart.js";

// #12 Hack om ervoor te zorgen dat de bubble charts het gaat doen. Door hier een expliciete
// referentie op te nemen worden de benodigde classes er door de treeshaker niet uitgegooid.
Chart.register(BubbleController);

document.title =
  getSportFromHostname() === "kite" ? "Weather2Kite.nl" : "Soarcast.nl";

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
