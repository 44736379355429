import styled from "styled-components";

export const BorderTable = styled.table`
  border-collapse: collapse;

  th,
  td {
    border: solid 1px #aaa;
    padding: 2px 5px;
  }
`;
