import React, { useState } from "react";

import { OverviewLegend } from "../OverviewLegend";
import { OverviewTable } from "../OverviewTable";
import { convertRemToPixels } from "../../util/util";
import { WindowSize } from "../../datastructures";
import { Link } from "react-router-dom";

interface OverviewProperties {
  homespotIds: number[];
  windowSize: WindowSize;
}

export const Overview: React.FC<OverviewProperties> = ({
  homespotIds,
  windowSize,
}) => {
  const nowTimestampSecs = Math.round(Date.now() / 1000);
  const [startTimestampSecs, lowLevelSetStartTimestampSecs] =
    useState(nowTimestampSecs);

  const setStartTimestampSecs = (secs: number) =>
    lowLevelSetStartTimestampSecs(
      secs < nowTimestampSecs ? nowTimestampSecs : secs
    );

  const colsToPlot = Math.floor(
    (windowSize.innerWidth - convertRemToPixels(15)) / convertRemToPixels(3)
  );
  const buttonStep = colsToPlot - 1;
  const sixHours = buttonStep * 3600;

  if (!homespotIds.length) {
    return (
      <>
        <br />
        <br />
        <center>
          Voordat je de overzichtpagina kunt gebruiken moet je eerst homespots
          kiezen op het <Link to="/settings">instellingen scherm.</Link>
        </center>
      </>
    );
  }

  return (
    <>
      <br />
      <button
        onClick={() => setStartTimestampSecs(startTimestampSecs - sixHours)}
      >
        {buttonStep} uur eerder
      </button>
      <button onClick={() => setStartTimestampSecs(nowTimestampSecs)}>
        Nu
      </button>
      <button
        onClick={() => setStartTimestampSecs(startTimestampSecs + sixHours)}
      >
        {buttonStep} uur later
      </button>
      <OverviewTable
        startTimestampSecs={startTimestampSecs}
        homespotIds={homespotIds}
        colsToPlot={colsToPlot}
      />
      <br />
      <OverviewLegend />
      <br />
      <h4>Bekijk ook meteen even:</h4>
      <ul>
        <li>
          {" "}
          <a
            href="http://www.knmi.nl/waarschuwingen_en_verwachtingen/weerkaarten.php"
            target="_blank"
            rel="noreferrer"
          >
            KNMI Weerkaartjes
          </a>
        </li>

        <li>
          {" "}
          <a
            href="http://www.knmi.nl/nederland-nu/weer/waarschuwingen-en-verwachtingen/extra/guidance-modelbeoordeling"
            target="_blank"
            rel="noreferrer"
          >
            Modelbeoordeling
          </a>
        </li>
        <li>
          {" "}
          <a href="http://www.buienradar.nl/" target="_blank" rel="noreferrer">
            Buienradar
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://www.knmi.nl/waarschuwingen_en_verwachtingen/luchtvaart/weerbulletin_kleine_luchtvaart.html"
            target="_blank"
            rel="noreferrer"
          >
            Weerbulletin voor de kleine luchtvaart
          </a>{" "}
          <a
            href="http://nos.nl/teletekst#707"
            target="_blank"
            rel="noreferrer"
          >
            (via teletekst)
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://blipmaps.nl/index.php?Day=0&Lang=Nl&Stage=2"
            target="_blank"
            rel="noreferrer"
          >
            RASP voorspelling voor Nederland
          </a>{" "}
        </li>
      </ul>
      <h4>Bron (Deze pagina en de kaart):</h4>

      <p>
        <b>Windmetingen</b>: Diverse weerstations van{" "}
        <a href="http://www.knmi.nl" target="blank" rel="noreferrer">
          KNMI
        </a>
        {", "}
        <a
          href="https://www.knmi.nl/kennis-en-datacentrum/uitleg/wow-nl"
          target="_blank"
          rel="noreferrer"
        >
          KNMI WOW
        </a>
        {", "}
        <a
          href="http://www.rijkswaterstaat.nl/"
          target="_blank"
          rel="noreferrer"
        >
          Rijkswaterstaat
        </a>
        {", "}
        <a
          href="https://www.kitesurfvereniging.nl/"
          target="_blank"
          rel="noreferrer"
        >
          Nederlandse kitesurfvereniging
        </a>
        {", "}
        <a href="https://www.kzvs.nl/" target="_blank" rel="noreferrer">
          KZVS
        </a>
        {", "}
        <a href="https://wolderwijdweer.nl/" target="_blank" rel="noreferrer">
          WolderwijdWeer
        </a>
        {", "}
        <a href="https://www.windmeting.nl/" target="_blank" rel="noreferrer">
          Kitesurfvereniging Airtime
        </a>
        {", "}
        <a href="https://www.meteotoren.nl/" target="_blank" rel="noreferrer">
          Meteotoren
        </a>
        <br />
        <b>Voorspellingen</b>: Het Harmonie model van{" "}
        <a href="http://www.knmi.nl" target="blank" rel="noreferrer">
          KNMI
        </a>
        <br />
        Voorspellingen worden alleen verzameld voor locaties waar ook een meting
        van beschikbaar is. Voor de limieten en gebruikte weerstations van
        iedere activiteit, zie op de pagina van de specifieke activiteit.
      </p>
    </>
  );
};
