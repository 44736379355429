import { Inline, InlineCluster } from "@bedrock-layout/primitives";
import { Link } from "react-router-dom";
import React from "react";

export const Menu: React.FC = () => {
  return (
    <Inline stretch={1} gutter="lg">
      <img
        src={process.env.PUBLIC_URL + "/images/soaring2.png"}
        alt=""
        className="headerImage"
      />

      <InlineCluster gutter="md" justify="center">
        <Link to="/kaart">Kaart</Link>
        <Link to="/overzicht">Overzicht</Link>
        <Link to="/kalender#">Kalender</Link>
        <Link to="/spotinfo">Informatie per spot</Link>
        <Link to="/locationinfo#">Ruwe meetgegevens</Link>
        <Link to="/settings">Instellingen</Link>
        <Link to="/over">Over soarcast</Link>
      </InlineCluster>
      <Inline gutter="md">
        <img
          src={process.env.PUBLIC_URL + "/images/kiting.png"}
          alt=""
          className="headerImage"
        />
      </Inline>
    </Inline>
  );
};
