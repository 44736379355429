import { MasonryGrid } from "@bedrock-layout/primitives";
import { clone } from "../util/util";
import { SpotData } from "../datastructures";
import React from "react";

interface HomespotSettingProperties {
  spotData: SpotData[];
  homespotIds: number[];
  setHomespotIds: (homespotIds: number[]) => void;
}

export const HomespotSetting: React.FC<HomespotSettingProperties> = ({
  spotData,
  homespotIds,
  setHomespotIds,
}) => {
  const onSpotSelectionChange = (id: number) => {
    const selectedCheckboxes = clone(homespotIds);

    const findIdx = selectedCheckboxes.indexOf(id);

    if (findIdx > -1) {
      selectedCheckboxes.splice(findIdx, 1);
    } else {
      selectedCheckboxes.push(id);
    }

    setHomespotIds(selectedCheckboxes);
  };

  const siteSpotData = spotData?.filter((s) => s.kite || s.soar);

  const allSpotIds = siteSpotData?.map((s) => s.id);

  return (
    <>
      {siteSpotData && homespotIds ? (
        <div>
          <MasonryGrid gutter="xs" minItemWidth="15rem">
            <button onClick={() => setHomespotIds(allSpotIds)}>
              Selecteer alle spots
            </button>
            <button onClick={() => setHomespotIds([])}>
              Selecteer geen spots
            </button>

            {siteSpotData
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((spot) => (
                <label key={spot.id}>
                  <input
                    key={"cb_" + spot.id + homespotIds.includes(spot.id)}
                    type="checkbox"
                    onChange={() => onSpotSelectionChange(spot.id)}
                    defaultChecked={homespotIds.includes(spot.id)}
                  />
                  {spot.name}
                </label>
              ))}
          </MasonryGrid>
        </div>
      ) : (
        "loading"
      )}
    </>
  );
};
