import React from "react";
import {
  convertCurrent,
  formatDateFlexLength,
  localDateTimeFromEpoch,
  roundPlaces,
  roundTowardZero,
} from "../util/util";
import { TideWindData } from "../datastructures";
import { Speed } from "./Speed";
import UnicodeSmileyFont from "./UnicodeSmiley";

interface ActueelGetijProperties {
  spotId: number;
  tideWindData: TideWindData[];
}

export const ActueelGetij: React.FC<ActueelGetijProperties> = ({
  spotId,
  tideWindData,
}) => {
  const tideTimes: Number[] = tideWindData.map((x) => x.validtime);
  const futureTideTimes: number[] = tideTimes
    .filter(Boolean)
    .map((x: Number) => x.valueOf())
    .filter((x) => x > Date.now() / 1000);
  const nextTideTime: Number = Math.min(...futureTideTimes);
  const nextTideInfo: TideWindData = tideWindData.filter(
    (x) => x.validtime === nextTideTime
  )[0];
  const displayTideTime = formatDateFlexLength(
    localDateTimeFromEpoch(nextTideTime?.valueOf())
  );

  const currDir = nextTideInfo
    ? nextTideInfo.upwind_cur_speed >= 0
      ? "tegen de wind in"
      : "met de wind mee"
    : "";

  const upSpeedMsec: number = nextTideInfo?.upwind_cur_speed;
  const flowsUp: boolean = upSpeedMsec > 0;

  const intensity: string = Math.round(
    Math.min(1, Math.abs(upSpeedMsec)) * 255
  ).toString();
  const green: string = flowsUp ? intensity : "0";
  const red: string = flowsUp ? "0" : intensity;

  const currColor = "rgb(" + red + ", " + green + ", 0, 1)";
  const currWebkitColor = "rgba(" + red + ", " + green + ", 0, 1)";

  const colorCss = {
    color: currColor,
    WebkitTextFillColor: currWebkitColor,
    WebkitOpacity: 1,
  };
  //
  // Smileys per halve knoop stroomsnelheid
  const smileys: Record<string, string> = {
    "-6": "⚠️⚠️🏴‍☠️",
    "-5": "⚠️⚠️⚠️",
    "-4": "😭⚠️",
    "-3": "😫",
    "-2": "😟",
    "-1": "😕",
    "0": "",
    "1": "🙂",
    "2": "😀",
    "3": "😁🤙",
    "4": "😁🤙⚠️",
    "5": "😬🤙⚠️",
    "6": "😬⚠️⚠️",
  };

  // +0 converts -0 into 0 if appliccable
  const smiley =
    smileys[
      (roundTowardZero(convertCurrent(upSpeedMsec, "kts") * 2) + 0).toString()
    ];

  return (
    <>
      {nextTideInfo ? (
        <li>
          Getij info ({displayTideTime}):
          <ul>
            <li>
              Stroming in windrichting:{" "}
              <span style={colorCss}>
                <Speed
                  value={Math.abs(nextTideInfo?.upwind_cur_speed)}
                  fractionDigits={2}
                />{" "}
                {currDir} <UnicodeSmileyFont smiley={smiley} />
              </span>
            </li>
            <li>Waterstand: {roundPlaces(nextTideInfo?.sep, 2)} m</li>
          </ul>
        </li>
      ) : (
        <></>
      )}
    </>
  );
};
