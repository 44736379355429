import { useStaticTableDataFromRemote } from "../util/useStaticTableDataFromRemote";
import { NkvSpot } from "../datastructures";
import React from "react";

interface NkvSpotLinksProperties {
  spotId: number;
}

export const NkvSpotLinks: React.FC<NkvSpotLinksProperties> = ({ spotId }) => {
  const nkvSpotData: NkvSpot[] = useStaticTableDataFromRemote("table=nkv_spot");
  // if (nkvSpotData == null) {
  //   return <></>;
  // }

  const filteredSpotData = nkvSpotData?.filter(
    (d: NkvSpot) => d.soarcast_spot_id === spotId,
  );

  return (
    <>
      {filteredSpotData.length ? (
        <>
          Voor officiële NKV spot informatie zie:{" "}
          {filteredSpotData.map((spot: NkvSpot, index) => (
            <React.Fragment key={spot.nkv_shortname}>
              <a
                href={
                  "https://kitesurfvereniging.nl/kitespot/" +
                  spot.nkv_shortname +
                  "/#info"
                }
                target="_blank"
                rel="noreferrer"
              >
                {spot.nkv_name}
              </a>
              {index < filteredSpotData.length - 1
                ? index < filteredSpotData.length - 2
                  ? ", "
                  : " of "
                : ""}
            </React.Fragment>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
