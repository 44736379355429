import React, { ChangeEvent } from "react";
import { updateItem } from "react-use-cookie";

interface WindUnitSettingProperties {
  windUnits: string;
  setWindUnits: updateItem;
}

export const WindUnitSetting: React.FC<WindUnitSettingProperties> = ({
  windUnits,
  setWindUnits,
}) => {
  const onWindUnitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWindUnits(e.target.value);
  };

  return (
    <div onChange={onWindUnitsChange}>
      <input
        type="radio"
        value="ms"
        defaultChecked={windUnits === "ms"}
        name="windUnits"
      />{" "}
      M/Sec
      <input
        type="radio"
        value="kts"
        defaultChecked={windUnits === "kts"}
        name="windUnits"
      />{" "}
      Knopen
      <input
        type="radio"
        value="kmh"
        defaultChecked={windUnits === "kmh"}
        name="windUnits"
      />{" "}
      KM/Uur
    </div>
  );
};
