import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Stack } from "@bedrock-layout/primitives";
import { SoarcastGraph } from "../SoarcastGraph";
import { useQueryCookieParam } from "../../util/useQueryCookieParam";
import { AppContext } from "../../util/Contexts";
import { useQueryStateParam } from "../../util/useQueryStateParam";
import { NumberParam, StringParam } from "use-query-params";
import { DuringDayOnlySwitch } from "../DuringDayOnlySwitch";
import { SC_BASE_PATH } from "../../util/util";
import { LocationData, WindowSize } from "../../datastructures";
import { MeasurementSourceLink } from "../MeasurementSourceLink";

interface LocationInfoProperties {
  windUnits: string;
  locationData: LocationData[];
  duringDayOnly: boolean;
  setDuringDayOnly: Dispatch<SetStateAction<boolean>>;
  windowSize: WindowSize;
}

export const LocationInfo: React.FC<LocationInfoProperties> = ({
  windUnits,
  locationData,
  duringDayOnly,
  setDuringDayOnly,
  windowSize,
}) => {
  const { dayState, setDayState } = useContext(AppContext);

  const [day, setDay] = useQueryStateParam<string>(
    "day",
    StringParam,
    dayState,
    setDayState
  );

  const [locationId, setLocation] = useQueryCookieParam(
    "location",
    NumberParam,
    117
  );

  const [runsToShow, setRunsToShow] = useState("lastOnly");

  const onRunsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    setRunsToShow(e.target.value);
  };

  const onLocationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLocation(parseInt(e.target.value));
  };

  const locationName =
    locationData.find((l) => l.id === locationId)?.location_name ||
    "Onbekende locatie";

  return (
    <Stack>
      <div>
        <h1>SoarCast Ruwe meetgegevens</h1>
        <h1>SoarCast Informatie voor {locationName}</h1>
        {locationData ? (
          <>
            <select onChange={onLocationChange} value={locationId}>
              {locationData.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.location_name}
                </option>
              ))}
            </select>
            {"  "}
            (dropdown is gesorteerd van noord naar zuid) Zie ook de weerstations
            in <a href={SC_BASE_PATH + "/weerstations.kml.php"}>
              google earth
            </a>{" "}
            en in{" "}
            <a href={SC_BASE_PATH + "/zeeland.php#screenshot_zeeland"}>
              screendump
            </a>
            <br />
            <button onClick={() => setDay("yesterday")}>Gisteren</button>
            <button onClick={() => setDay("today")}>Vandaag</button>
            <button onClick={() => setDay("tomorrow")}>Morgen</button>
            <button onClick={() => setDay("dayaftertomorrow")}>
              Overmorgen
            </button>
            <DuringDayOnlySwitch
              duringDayOnly={duringDayOnly}
              setDuringDayOnly={setDuringDayOnly}
            />
            <br />
            <select onChange={onRunsChange} value={runsToShow}>
              <option key={"lastOnly"} value="lastOnly">
                Alleen de meest recente model run voor ieder tijdstip
              </option>
              <option key={"raw"} value="raw">
                Alle model runs
              </option>
              <option key={"all"} value="all">
                Alle model runs en de meest recente
              </option>
            </select>
          </>
        ) : (
          "loading"
        )}
        {locationId && (
          <SoarcastGraph
            locationId={locationId}
            locationName={locationName}
            day={day}
            unifiedUnit="m/s"
            windUnits={windUnits}
            duringDayOnly={duringDayOnly}
            windowSize={windowSize}
            runsToShow={runsToShow}
          />
        )}
        {locationId && (
          <SoarcastGraph
            locationId={locationId}
            locationName={locationName}
            day={day}
            unifiedUnit="deg"
            windUnits={windUnits}
            duringDayOnly={duringDayOnly}
            windowSize={windowSize}
            runsToShow={runsToShow}
          />
        )}
        {locationId && (
          <SoarcastGraph
            locationId={locationId}
            locationName={locationName}
            day={day}
            unifiedUnit="C"
            windUnits={windUnits}
            duringDayOnly={duringDayOnly}
            windowSize={windowSize}
            runsToShow={runsToShow}
          />
        )}
        {locationId && (
          <SoarcastGraph
            locationId={locationId}
            locationName={locationName}
            day={day}
            unifiedUnit="hPa"
            windUnits={windUnits}
            duringDayOnly={duringDayOnly}
            windowSize={windowSize}
            runsToShow={runsToShow}
          />
        )}
      </div>
      {locationId && (
        <div>
          <b>Bron</b>: <br />
          <b>Metingen</b>:{" "}
          <MeasurementSourceLink
            location={locationId}
            allLocationData={locationData}
            linkLocationLocal={false}
          />
          <br />
          <b>Voorspellingen</b>: Het Harmonie model van{" "}
          <a href="http://www.knmi.nl" target="blank">
            KNMI
          </a>
        </div>
      )}
    </Stack>
  );
};
