import React, { Dispatch, SetStateAction } from "react";

interface DuringDayOnlySwitchProperties {
  duringDayOnly: boolean;
  setDuringDayOnly: Dispatch<SetStateAction<boolean>>;
}

export const DuringDayOnlySwitch: React.FC<DuringDayOnlySwitchProperties> = ({
  duringDayOnly,
  setDuringDayOnly,
}) => {
  return (
    <label>
      <input
        type="checkbox"
        onChange={() => setDuringDayOnly(!duringDayOnly)}
        defaultChecked={duringDayOnly}
      />
      &nbsp;Alleen&nbsp;overdag&nbsp;(7:00-19:00)
    </label>
  );
};
