import { useQueryParam } from "./useQueryParam";
import useCookie from "react-use-cookie";
import { useEffect } from "react";
import { QueryParamConfig } from "serialize-query-params/src/types";
import { SOARCAST_COOKIE_CONFIG } from "./usePersistentState";

export function useQueryCookieParam<T>(
  name: string,
  paramType: QueryParamConfig<T | null, T | null | undefined>,
  defaultValue: T,
): [T, (value: T) => void] {
  const [cookieValue, setCookieValue] = useCookie(
    name,
    paramType.encode(defaultValue) as string,
  );
  const [queryValue, setQueryValue] = useQueryParam(name);

  useEffect(() => {
    // comparing the json encoded strings here for a reliable equality check
    if (cookieValue !== queryValue) {
      if (queryValue) {
        setCookieValue(queryValue, SOARCAST_COOKIE_CONFIG);
      } else {
        setQueryValue(cookieValue);
      }
    }
  }, [cookieValue, queryValue, setQueryValue, setCookieValue]);

  return [
    paramType.decode(cookieValue) as T,
    (value) => setQueryValue(paramType.encode(value) as string),
  ];
}
